import { Fragment, useState, useEffect, useCallback } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router";
import { DEFAULT_COLORS } from "../../views/Base/Base";
import { getQueryParameter } from "./../../utils/queryParams";

const OrganisationSiteSelector = (props) => {
    const [selectedOrg, setSelectedOrg] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const orgIdParam = getQueryParameter("organisationId");
    const history = useHistory();

    useEffect(() => {
        if (props.organisations && props.organisations[0] && !selectedOrg) {
            let selected = props.organisations.find(
                (org) => org.id === parseInt(orgIdParam)
            );
            setSelectedOrg(selected);
        }
        if (props.sites && props.sites[0] && !selectedSite) {
            setSelectedSite(props.sites[0]);
        }
    }, [orgIdParam, props, selectedOrg, selectedSite]);

    const handleOrganisationSiteChange = useCallback(
        (siteId) => {
            if (!selectedOrg) return;
            history.push(`?organisationId=${selectedOrg.id}&siteId=${siteId}`);
        },
        [selectedOrg, history]
    );

    if (!selectedOrg) return <div></div>;

    return (
        <div className="absolute w-40 sm:w-72 border-2 bg-white">
            <Listbox
                value={selectedOrg}
                onChange={(e) => {
                    console.log(e);
                    setSelectedOrg(e);
                    props.setOrg({
                        ...e,
                        theme: e.theme ? e.theme : DEFAULT_COLORS,
                    });
                }}
            >
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md sm:text-sm text-2xs">
                        <span className="block truncate">
                            {selectedOrg.name}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Listbox.Options className="box-content absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-2xs shadow-lg sm:text-sm">
                        {props.organisations.map((org, i) => (
                            <Listbox.Option
                                key={i}
                                className={`cursor-pointer select-none relative py-1 px-2 text-xs sm:text-sm hover:bg-teal-500`}
                                value={org}
                            >
                                {org.name}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
            <Listbox
                value={selectedSite}
                onChange={(e) => {
                    setSelectedSite(e);
                    props.setSite(e);
                    handleOrganisationSiteChange(e.id);
                }}
            >
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md text-2xs sm:text-sm">
                        <span className="block truncate">
                            {selectedSite ? selectedSite.name : "-"}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Listbox.Options className="box-content absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-2xs shadow-lg sm:text-sm">
                        {props.sites.map((site, i) => (
                            <Listbox.Option
                                key={i}
                                className={`cursor-pointer select-none relative py-1 px-2 text-xs sm:text-sm hover:bg-teal-500`}
                                value={site}
                            >
                                {site.name}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
        </div>
    );
};

export default OrganisationSiteSelector;
