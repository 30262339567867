import React from "react";
import { handleTime } from "../../utils/helper";

const RequestPanel = (props) => {
    if (props.data === undefined) return <div></div>;
    return (
        <div
            className={`bg-white p-2 sm:p-5 rounded-md flex flex-col ${
                props.closed && "opacity-60"
            }`}
        >
            <div className="sm:text-lg text-xs col-span-2 font-semibold">
                {props.data.description}
            </div>
            {props.data.requests.map((request, index) => {
                return window.innerWidth > 640 ? (
                    <div key={index} className="grid grid-cols-8 gap-x-2">
                        <div className="col-start-1 col-span-3 xs:text-sm text-md">
                            {request.description}
                        </div>
                        <div className="col-start-4 col-span-4 xs:text-sm text-md ">
                            {request.details}
                        </div>
                        <div className="col-start-8 col-span-1 justify-self-end text-xs sm:text-md">
                            {handleTime(request.time || request.time_closed)}
                        </div>
                    </div>
                ) : (
                    <div
                        key={index}
                        className="flex flex-row justify-between space-x-2 text-xs"
                    >
                        <div>{request.description}</div>
                        <div>
                            {handleTime(request.time || request.time_closed)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default RequestPanel;
