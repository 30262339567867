import React from "react";
import CommonButton from "../CommonButton";
import OrgInfo from "../OrgInfo/OrgInfo";
import SideTab from "../SideTab/SideTab";
import Trademark from "../Trademark";
import { Link } from "react-router-dom";
import classnames from "classnames";

const SideBar = (props) => {
    return (
        <div
            className={classnames(
                `bg-${props.organisation.theme.primary} xl:w-72 lg:w-56 w-48 h-screen fixed`
            )}
        >
            <div className="flex flex-col justify-between h-screen">
                <div className="w-full">
                    <OrgInfo
                        name={props.site.name}
                        logo={props.organisation.logo_image_filename}
                    />
                    <div className="mt-8 px-8">
                        <Link
                            to={`/submitRequest/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                        >
                            <CommonButton
                                colour={props.organisation.theme.secondary}
                                title="REQUEST"
                                width="full"
                            />
                        </Link>
                    </div>
                </div>
                <div className="mb-60 space-y-5">
                    <Link
                        to={`/home/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                    >
                        <SideTab
                            name="Home"
                            icon="HomeIcon"
                            organisation={props.organisation}
                        />
                    </Link>
                    <Link
                        to={`/overview/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                    >
                        <SideTab
                            name="Overview"
                            icon="TemplateIcon"
                            organisation={props.organisation}
                        />
                    </Link>
                    <Link
                        to={`/requests/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                    >
                        <SideTab
                            name="Requests"
                            icon="ExclamationIcon"
                            organisation={props.organisation}
                        />
                    </Link>
                    {props.organisation.subscriptions.find(
                        (subscription) => subscription.service === "Deliveries"
                    ) && (
                        <Link
                            to={`/deliveries/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                        >
                            <SideTab
                                name="Deliveries"
                                icon="TruckIcon"
                                organisation={props.organisation}
                            />
                        </Link>
                    )}
                    <Link
                        to={`/settings/?organisationId=${props.organisation.id}&siteId=${props.site.id}`}
                    >
                        <SideTab
                            name="Settings"
                            icon="CogIcon"
                            organisation={props.organisation}
                        />
                    </Link>
                </div>
                <div className="mb-6">
                    <div className="ml-7">
                        <Trademark />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
