import React, { useEffect, useState } from "react";
import OrgInfo from "../OrgInfo/OrgInfo";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";
import classnames from "classnames";
import MobileDropdown from "../MobileDropdown/MobileDropdown";

const QUERY_SITE_NAME_FROM_ID = gql`
    query siteNameFromId($siteId: Int!) {
        site_by_pk(id: $siteId) {
            id
            name
        }
    }
`;

const MobileNav = (props) => {
    const [siteName, setSiteName] = useState();
    const siteId = getQueryParameter("siteId");
    const [isOpen, setIsOpen] = useState(true);

    const { data: siteData, error: siteError } = useQuery(
        QUERY_SITE_NAME_FROM_ID,
        {
            skip: siteId === undefined,
            variables: {
                siteId: siteId,
            },
            onError: () => console.log(siteError),
        }
    );

    useEffect(() => {
        if (siteData === undefined) return;
        setSiteName(siteData.site_by_pk.name);
    }, [siteData]);

    if (siteId === undefined) return <div></div>;

    return (
        <div
            className={classnames(
                `bg-${props.organisation.theme.primary}`,
                "w-full h-14 flex flex-row items-center justify-between z-40"
            )}
        >
            <div>
                <MobileDropdown
                    open={isOpen}
                    close={setIsOpen}
                    bg={props.organisation.theme.primary}
                    {...props}
                />
            </div>
            <div>
                <OrgInfo
                    name={siteName}
                    logo={props.organisation.logo_image_filename}
                />
            </div>
        </div>
    );
};

export default MobileNav;
