import React from "react";
import { Logo } from "../../assets/SVGs/Logo";
import Logout from "../../components/Logout/Logout";
import PillButton from "../../components/PillButton";

const PermissionDenied = (props) => {
    return (
        <div className="w-full h-full flex flex-row justify-center">
            <div className="mt-10 py-10 m-auto grid place-items-center text-blueGray-800 text-base border-2 rounded-md shadow-lg bg-white max-w-100 mx-2">
                <div className="flex flex-row space-x-5 items-center pb-5">
                    <div>
                        <Logo width={60} height={60} />
                    </div>
                    <div className="text-4xl self-center">BenchMarker</div>
                </div>
                <div className="text-xl pb-5">Thank you for logging in.</div>
                <div className="grid place-items-center w-full text-sm px-5">
                    <div>
                        This account does not have any permissions. Please
                        contact your organisation's administrator to assign
                        policies and gain access to the app.
                    </div>
                    <button
                        className="bg-teal-500 rounded-3xl text-white w-full h-10 mt-10"
                        onClick={() => Logout()}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PermissionDenied;
