import { gql, useQuery } from "@apollo/client";
import { DownloadIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import CommonButton from "../../components/CommonButton";
import NewDelivery from "../../components/Deliveries/NewDelivery";
import { getUserSession } from "../../utils/userSession";
import { getFile } from "../../utils/s3Service";

const DELIVERY_QUERY = gql`
  query getReceipts($id: Int!) {
    site_by_pk(id: $id) {
      id
      name
      data_receipts(order_by: { created: desc }) {
        id
        start_time
        end_time
        report_link
        name
        delivery_point {
          id
          name
          address {
            street
            suburb
            id
            code
            city
          }
        }
      }
    }
  }
`;

export const Deliveries = (props) => {
  const [newDelivery, setNewDelivery] = useState(false);
  const [deliveryHistory, setDeliveryHistory] = useState([]);

  const {
    data: deliveryData,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(DELIVERY_QUERY, {
    variables: {
      id: props.site.id,
    },
  });

  useEffect(() => {
    if (!deliveryData) return;
    let report_link_missing = false;
    deliveryData.site_by_pk.data_receipts.map((receipt) => {
      if (receipt.report_link === null) {
        report_link_missing = true;
      }
      return null;
    });
    if (report_link_missing && startPolling) {
      startPolling(20000);
    } else if (!report_link_missing && stopPolling) {
      stopPolling();
    }
    setDeliveryHistory(deliveryData.site_by_pk.data_receipts);
  }, [deliveryData, startPolling, stopPolling]);

  function download(blob, filename) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = blob;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blob);
    }
  }

  const downloadReport = (fileName, name) => {
    let session = getUserSession();
    let token = session.credentials.idToken;
    getFile(fileName, token).then((r) => {
      download(r, name + ".pdf");
    });
  };

  const Loading = () => {
    return (
      <div className="flex flex-row space-x-3 items-center pt-2">
        <div
          style={{ animationDelay: "0.1s" }}
          className="w-1 h-1 rounded-full bg-blueGray-800 animate-ping"
        ></div>
        <div
          style={{ animationDelay: "0.3s" }}
          className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
        ></div>
        <div
          style={{ animationDelay: "0.5s" }}
          className="w-1 h-1  rounded-full bg-blueGray-800 animate-ping"
        ></div>
      </div>
    );
  };

  if (newDelivery) {
    return (
      <NewDelivery
        show={newDelivery}
        setNewDelivery={setNewDelivery}
        {...props}
        refetch={refetch}
      />
    );
  }
  return (
    <div className="max-w-lg m-2  overflow-y-auto pb-40 h-full sm:px-4">
      <div
        className={classNames(
          `text-${props.organisation.theme.secondary}`,
          "text-2xl pb-5"
        )}
      >
        Deliveries
      </div>
      <CommonButton
        width={"1/2"}
        submit={false}
        colour={props.organisation.theme.secondary}
        title={"New Delivery"}
        onClick={() => setNewDelivery(true)}
      />
      {deliveryHistory && deliveryHistory.length > 0 && (
        <div className="mt-4">
          <div
            className={classNames(
              `text-${props.organisation.theme.secondary}`,
              "text-xl pb-2.5 "
            )}
          >
            History
          </div>
          <div>
            {deliveryHistory.map((receipt, index) => {
              return (
                <div
                  key={index}
                  className="bg-white rounded-lg p-2 mt-2 flex flex-row justify-between"
                >
                  <div>
                    {receipt.name}
                    <div className="text-sm">
                      {receipt.delivery_point.address.street}
                      {", "}
                      {receipt.delivery_point.address.city}
                    </div>

                    <div className="text-sm">
                      {dayjs(receipt.start_time).format("DD/MM HH:mm a")}
                      {" -> "}
                      {dayjs(receipt.end_time).format("DD/MM HH:mm a")}{" "}
                    </div>
                  </div>
                  {receipt.report_link ? (
                    <div
                      onClick={() =>
                        downloadReport(receipt.report_link, receipt.name)
                      }
                      className="self-center mr-2 border-2 p-1 rounded-lg border-teal-500 cursor-pointer"
                    >
                      <DownloadIcon className="h-6 w-6 text-teal-500" />
                    </div>
                  ) : (
                    <div className="self-center ">
                      <Loading />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
