import React from "react";
import "./index.css";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import Base from "./views/Base/Base";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./views/Login";
import { createClient } from "./utils/graphql";
import { Provider } from "react-redux";
import { store } from "./redux";
import { createRoot } from "react-dom/client";
import ForgotPassword from "./views/ForgotPassword";
import SignUp from "./views/SignUp";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://77c458e00d2d4488843c05c589514730@o4507920890200064.ingest.us.sentry.io/4508100620582912",
    environment: process.env.REACT_APP_STAGE,
  });
}
createClient(process.env.REACT_APP_API_URL + "/graphql").then((client) => {
  const root = createRoot(document.getElementById("root"));

  root.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Provider store={store}>
          <React.Suspense
            fallback={<div>loading...</div>}
            basename={process.env.REACT_APP_ROUTER_BASE || ""}
          >
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/signup" component={SignUp} />

              <Route path={["/", "/home"]} component={Base} />
            </Switch>
          </React.Suspense>
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  );
});
