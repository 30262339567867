import React from "react";
import dayjs from "dayjs";

const DatePicker = (props) => {
    if (props === undefined) return <div></div>;

    const handleInputChange = (event) => {
        let split = event.target.value.split("-");
        let year = split[0];
        let month = split[1] - 1;
        let day = split[2];
        event.persist();
        props.setDate((date) => {
            date = date.year(year);
            date = date.month(month);
            date = date.date(day);
            return date;
        });
    };

    return (
        <div className="w-full">
            {props.title && <div className="pb-1">Date</div>}
            <div className="relative">
                <input
                    type="date"
                    name="date"
                    value={props.value.format("YYYY-MM-DD")}
                    placeholder={dayjs().format("DD/MM/YYYY")}
                    className="rounded-md border-0 text-sm h-10 p-2 w-full"
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                        e.currentTarget.type = "date";
                        if (document.getElementById("dateIcon") !== null) {
                            document.getElementById("dateIcon").remove();
                        }
                        e.currentTarget.focus();
                    }}
                ></input>
            </div>
        </div>
    );
};

export default DatePicker;
