import React from "react";
import SignInForm from "../../components/SignInForm/SignInForm";
import { Logo } from "../../assets/SVGs/Logo";

const Login = () => {
    if (window.innerWidth > 640)
        return (
            <div className="flex flex-row flex-shrink">
                <div className="bg-blueGray-800 flex items-center w-1/2 h-screen flex-1 justify-center">
                    <div className="flex flex-row flex-shrink">
                        <Logo width={102} height={102} />
                        <div>
                            <div className="text-white lg:text-4xl text-3xl pt-4 lg:pl-8 pl-4 self-center">
                                BenchMarker
                            </div>
                            <div className="text-white lg:text-xl text-xl lg:pl-8 pl-4">
                                Shop Portal
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-bg flex flex-col text-xl justify-center gap-y-5 px-11 w-1/2 h-screen flex-1">
                    <div className="text-teal-500 font-semibold">Sign In</div>
                    <div className="mr-11">
                        <SignInForm />
                    </div>
                </div>
            </div>
        );
    else {
        return (
            <div className="bg-blueGray-800 w-full h-screen flex flex-col justify-center px-8 overflow-y-scroll">
                <div className="flex flex-row justify-start items-center">
                    <Logo width={75} height={75} />
                    <div>
                        <div className="text-white lg:text-xl text-xl  lg:pl-8 pl-4 self-center">
                            BenchMarker
                        </div>
                        <div className="text-white lg:text-md text-md lg:pl-8 pl-4">
                            Shop Portal
                        </div>
                    </div>
                </div>
                <div className="flex flex-col pt-8">
                    <div className="text-teal-500 text-lg font-semibold pb-4">
                        Sign In
                    </div>
                    <div className="mr-11">
                        <SignInForm />
                    </div>
                </div>
            </div>
        );
    }
};

export default Login;
