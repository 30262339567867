import React from "react";
import classnames from "classnames";
import { RefreshIcon } from "@heroicons/react/outline";

const CommonButton = (props) => {
    let width = props.width;
    let disabled = props.disabled;
    if (disabled !== true) {
        disabled = false;
    }
    if (!width) {
        width = 32;
    }
    return (
        <button
            onClick={props.onClick}
            className={classnames(
                `w-${width} bg-${
                    props.colour
                } h-9 px-5 py-2.5 rounded-md  flex flex-row justify-center opacity-${
                    disabled ? "75" : "100"
                }`
            )}
            disabled={props.submit || disabled}
        >
            <div className="text-white text-sm font-bold text-center self-center">
                {props.submit && (
                    <div className="flex justify-center">
                        <RefreshIcon className="w-5 h-5 animate-spin" />
                    </div>
                )}
                {!props.submit && <div>{props.title}</div>}
            </div>
        </button>
    );
};

export default CommonButton;
