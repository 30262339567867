import { Menu, Transition, Dialog } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { getQueryParameter } from "../../utils/queryParams";
import { Link } from "react-router-dom";
import CommonButton from "../CommonButton";
import SideTab from "../SideTab";
import Logout from "../Logout/Logout";
import Trademark from "../Trademark";
// import { Logo } from "../../assets/SVGs/Logo";

const MobileDropdown = (props) => {
  const siteId = getQueryParameter("siteId");
  const orgId = getQueryParameter("organisationId");
  const [isOpen, setIsOpen] = useState(true);
  let refDiv = useRef(null);

  return (
    <Menu as="div" className="relative inline-block text-left z-99">
      <div onClick={() => setIsOpen(true)}>
        <Menu.Button
          className={`} inline-flex w-full mt-2 justify-center rounded-md bg-${props.bg}  bg-opacity-20 py-2 text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <MenuIcon className="h-6 w-6 ml-4" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Dialog
        as="div"
        onClose={() => props.close(false)}
        open={isOpen}
        initialFocus={refDiv}
      >
        <div ref={refDiv}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-in duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-30"
            entered="opacity-30"
            leave="transition-opacity ease-out duration-300"
            leaveFrom="opacity-30"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black" />
          </Transition.Child>
        </div>
      </Dialog>

      <Transition
        as={Fragment}
        enter="transition ease-in-out duration-500 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <Menu.Items
          className={`absolute flex left-0 z-40 h-screen -mt-14 origin-top-right divide-y divide-gray-100 bg-${props.organisation.theme.primary} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div
            className={`${
              window.innerHeight < 640 ? "pt-16" : "pt-7"
            } flex flex-col items-center py-2 px-7 justify-evenly overflow-y-auto h-screen`}
          >
            {/* <div className="flex flex-row justify-start items-center">
                            <Logo width={52} height={52} />
                            <div>
                                <div className="text-white lg:text-md text-lg  lg:pl-8 pl-4 self-center">
                                    BenchMarker
                                </div>
                                <div className="text-white lg:text-sm text-md lg:pl-8 pl-4">
                                    Shop Portal
                                </div>
                            </div>
                        </div> */}
            <div className="overflow-y-auto">
              <div className="p-2 mb-3">
                <Menu.Item>
                  <Link
                    to={`/submitRequest/?organisationId=${orgId}&siteId=${siteId}`}
                  >
                    <CommonButton
                      width={"full"}
                      colour={props.organisation.theme.secondary}
                      title="REQUEST"
                    />
                  </Link>
                </Menu.Item>
              </div>
              <Menu.Item>
                <Link to={`/home/?organisationId=${orgId}&siteId=${siteId}`}>
                  <SideTab
                    name="Home"
                    icon="HomeIcon"
                    organisation={props.organisation}
                  />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to={`/overview/?organisationId=${orgId}&siteId=${siteId}`}
                >
                  <SideTab
                    organisation={props.organisation}
                    name="Overview"
                    icon="TemplateIcon"
                  />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  to={`/requests/?organisationId=${orgId}&siteId=${siteId}`}
                >
                  <SideTab
                    name="Requests"
                    icon="ExclamationIcon"
                    organisation={props.organisation}
                  />
                </Link>
              </Menu.Item>
              {props.organisation.subscriptions.find(
                (subscription) => subscription.service === "Deliveries"
              ) && (
                <Menu.Item>
                  <Link
                    to={`/deliveries/?organisationId=${orgId}&siteId=${siteId}`}
                  >
                    <SideTab
                      name="Deliveries"
                      icon="TruckIcon"
                      organisation={props.organisation}
                    />
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item>
                <Link
                  to={`/settings/?organisationId=${orgId}&siteId=${siteId}`}
                >
                  <SideTab
                    organisation={props.organisation}
                    name="Settings"
                    icon="CogIcon"
                  />
                </Link>
              </Menu.Item>
            </div>
            <div className="pb-10">
              <Menu.Item as="div">
                <Trademark />
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MobileDropdown;
