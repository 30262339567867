import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CommonButton from "../CommonButton";

const DeliveryCompleteModal = (props) => {
    return (
        <Transition
            as={Fragment}
            show={props.successModal}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Dialog
                open={props.successModal}
                onClose={() => props.closeSuccessModal()}
                className="relative z-55"
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30" />
                </Transition.Child>

                <div className="fixed inset-0 flex items-center justify-center p-4 h-full overflow-y-auto">
                    <Dialog.Panel className="w-full rounded-lg bg-white p-5 max-w-lg">
                        <Dialog.Title className={"pb-2 text-xl"}>
                            Delivery Complete
                        </Dialog.Title>
                        <div className="text-sm">
                            A temperature report has been scheduled and will be sent
                            to the following people once the data is available:
                        </div>
                        {props.selectedDeliveryPoint &&
                            props.selectedDeliveryPoint.email_targets.map(
                                (target, index) => {
                                    return (
                                        <div key={index} className="my-2">
                                            {target.email_address}
                                        </div>
                                    );
                                }
                            )}
                        <div className="mr-1 w-full">
                            <CommonButton
                                width={"full"}
                                submit={false}
                                colour={props.organisation.theme.secondary}
                                title={"Close"}
                                onClick={() => props.closeSuccessModal()}
                            />
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition>
    );
};

export default DeliveryCompleteModal;
