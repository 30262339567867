import React from "react";
import Logout from "./../../components/Logout/Logout";

const Settings = (props) => {
    return (
        <div className="pl-2 sm:pl-5">
            <div
                className={`text-${props.organisation.theme.secondary} text-2xl pb-10`}
            >
                Settings
            </div>
            <div
                className={`bg-${props.organisation.theme.secondary} w-32 text-center text-white rounded-md py-2 cursor-pointer`}
                onClick={() => Logout()}
            >
                Logout
            </div>
        </div>
    );
};

export default Settings;
