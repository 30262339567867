import classnames from "classnames";
import React, { useState, useEffect } from "react";

const ValueIndicator = (props) => {
    const [unit, setUnit] = useState("");

    useEffect(() => {
        if (props.name.includes("Temp")) setUnit("°C");
        else if (props.name === "Humidity") setUnit("%");
        else if (props.name === "CO2") setUnit("PPM");
        else {
            setUnit("");
        }
    }, [props.name, unit]);

    if (props.data === undefined || props.name === undefined)
        return <div></div>;
    return (
        <div>
            <div className="flex flex-col">
                <div className="flex flex-row z-10">
                    <div
                        className={classnames(
                            `text-${props.color.secondary}`,
                            "text-xl sm:text-2xl md:text-xl lg:text-2xl sm:pb-2 pb-1"
                        )}
                    >
                        {props.data}
                    </div>
                    <div
                        className={classnames(
                            `text-${props.color.secondary}`,
                            "text-xs sm:text-base inline-block pt-1.5"
                        )}
                    >
                        {unit}
                    </div>
                </div>
                <div className="text-gray-text text-xs font-semibold flex sm:pb-2 pb-1">
                    {props.name}
                </div>
            </div>
        </div>
    );
};

export default ValueIndicator;
