import React, { useEffect, useState } from "react";
import DynamicHeroIcon from "../DynamicHeroIcon/DynamicHeroIcon";
import classnames from "classnames";

const SideTab = (props) => {
  const [activeTab, setActiveTab] = useState(undefined);

  useEffect(() => {
    if (window.location.pathname.includes(props.name.toLowerCase())) {
      setActiveTab(true);
    } else {
      setActiveTab(false);
    }
  }, [props]);

  if (!props.organisation) return <div></div>;

  return (
    <div
      className={classnames(
        `bg-${
          activeTab
            ? props.organisation.theme.active
            : props.organisation.theme.primary
        } font-${
          activeTab ? "bold" : "normal"
        } h-14 xl:w-72 lg:w-56 w-48 flex flex-col justify-center cursor-pointer pl-6 lg:pl-10 my-5`
      )}
    >
      <div className="flex flex-row items-center">
        <DynamicHeroIcon icon={props.icon} width={5} height={5} />
        <div className="text-base text-white text-center px-5">
          {props.name}
        </div>
      </div>
    </div>
  );
};

export default SideTab;
