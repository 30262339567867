import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const ListBox = (props) => {
    if (props.list === undefined) return <div></div>;
    return (
        <Listbox
            value={props.selectedOption}
            onChange={props.setSelectedOption}
        >
            <div className="relative mt-1 w-full">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md h-10">
                    <span className="block truncate text-sm text-gray-text">
                        {props.selectedOption
                            ? props.itemLabel(props.selectedOption)
                            : props.placeholder}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronDownIcon
                            className="w-4 h-4 text-gray-400 "
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Listbox.Options className="w-full mt-1 text-base bg-white rounded-md absolute z-20 border max-h-60 sm:max-h-144 overflow-y-scroll box-content">
                    {props.list.map((option, index) => (
                        <Listbox.Option
                            key={index}
                            className={`cursor-pointer select-none relative py-1 px-2 text-sm hover:bg-gray-50`}
                            value={option}
                            disabled={option.unavailable}
                        >
                            {props.itemLabel(option)}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

export default ListBox;
