import { gql, useMutation, useQuery } from "@apollo/client";
import { Combobox, Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { getQueryParameter } from "../../utils/queryParams";
import CommonButton from "../CommonButton";
import DatePicker from "../FormComponents/DatePicker/DatePicker";
import ListBox from "../FormComponents/ListBox";
import TimePicker from "../FormComponents/TimePicker/TimePicker";
import DeliveryCompleteModal from "./DeliveryCompleteModal";

const ADDY_API_KEY = "607dac2a74284d4d856f96c2a1874120";

const NEW_DELIVERY_POINT_MUTATION = gql`
    mutation insertDeliveryPoint(
        $address_data: address_insert_input!
        $organisation_id: Int!
        $name: String!
        $email_data: [ppcs_core_deliveryemailtarget_insert_input!]!
        $created: timestamptz!
    ) {
        insert_ppcs_core_deliverypoint_one(
            object: {
                address: { data: $address_data }
                name: $name
                organisation_id: $organisation_id
                email_targets: { data: $email_data }
                created: $created
            }
        ) {
            id
            name
        }
    }
`;

const DELIVERY_POINTS_QUERY = gql`
    query getDeliveryPoints($id: Int!) {
        organisation_by_pk(id: $id) {
            delivery_points(order_by: { created: desc_nulls_last }) {
                id
                created
                address {
                    id
                    latitude
                    longitude
                    country
                    code
                    city
                    province
                    street
                    suburb
                }
                name
                email_targets {
                    id
                    email_address
                }
            }
        }
    }
`;

const MEASUREMENT_POINT_QUERY = gql`
    query getCelsorMeasurementPoints($id: Int!) {
        site_by_pk(id: $id) {
            id
            zones {
                id
                measurement_points(where: { device_type: { _eq: "Celsor" } }) {
                    device_type
                    id
                    name
                }
            }
        }
    }
`;

const CREATE_DATA_RECEIPT_MUTATION = gql`
    mutation insertDatareceipt(
        $data_receipt: ppcs_core_datareceipt_insert_input!
    ) {
        insert_ppcs_core_datareceipt_one(object: $data_receipt) {
            id
            name
            end_time
            site_id
            start_time
            report_link
            organisation_id
            measurement_point_id
            delivery_point_id
        }
    }
`;

const NewDelivery = (props) => {
    const [startTime, setStartTime] = useState(dayjs().subtract(8, "hours"));
    const [endTime, setEndTime] = useState(dayjs());
    const siteId = getQueryParameter("siteId");

    const [newDeliveryPointDialog, setNewDeliveryPointDialog] = useState(false);
    const [deliveryPointName, setDeliveryPointName] = useState("");
    const [newDeliveryAddress, setNewDeliveryAddress] = useState("");
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [newEmailAddresses, setNewEmailAddresses] = useState([""]);
    const [addressInfo, setAddressInfo] = useState();

    const [insertDeliveryPoint] = useMutation(NEW_DELIVERY_POINT_MUTATION);
    const [insertDataReceipt] = useMutation(CREATE_DATA_RECEIPT_MUTATION);

    const [newDeliveryPointError, setNewDeliveryPointError] = useState(null);
    const [dataReceiptError, setDataReceiptError] = useState(null);

    const [deliveryPointOptions, setDeliveryPointOptions] = useState([]);
    const [selectedDeliveryPoint, setSelectedDeliveryPoint] = useState();

    const [measurementPointOptions, setMeasurementPointOptions] = useState();
    const [selectedMeasurementPoint, setSelectedMeasurementPoint] = useState();

    const [successModal, setSuccessModal] = useState(false);

    // let refDiv = useRef(null);

    const { data: deliveryPointData, refetch: refetchDeliveryPoints } =
        useQuery(DELIVERY_POINTS_QUERY, {
            variables: {
                id: props.organisation.id,
            },
        });

    const { data: measurementPointData } = useQuery(MEASUREMENT_POINT_QUERY, {
        variables: {
            id: siteId,
        },
    });

    useEffect(() => {
        if (!deliveryPointData) return;
        setDeliveryPointOptions(
            deliveryPointData.organisation_by_pk.delivery_points
        );
        if (deliveryPointData.organisation_by_pk.delivery_points.length > 0) {
            setSelectedDeliveryPoint(
                deliveryPointData.organisation_by_pk.delivery_points[0]
            );
        }
    }, [deliveryPointData]);

    useEffect(() => {
        if (!measurementPointData) return;
        let measurementPoints = [];
        measurementPointData.site_by_pk.zones.map((zone) => {
            return zone.measurement_points.map((mp) => {
                return measurementPoints.push(mp);
            });
        });
        setMeasurementPointOptions(measurementPoints);
        if (measurementPoints.length > 0) {
            setSelectedMeasurementPoint(measurementPoints[0]);
        }
    }, [measurementPointData]);

    const getAddresses = (searchTerm) => {
        if (!searchTerm || searchTerm.length < 1) return;
        const url = `https://api.addy.co.nz/search?key=${ADDY_API_KEY}&max=5&s=${searchTerm}`;
        fetch(url)
            .then((data) => {
                return data.json();
            })
            .then((result) => {
                if (!result.addresses) return;
                let addresses = [];
                result.addresses.map((add) => {
                    return addresses.push({
                        id: add.id,
                        label: add.a,
                    });
                });
                setAddressSuggestions(addresses);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getAddressInfo = (id) => {
        const url = `https://api-nz.addysolutions.com/address/${id}?key=${ADDY_API_KEY}`;
        fetch(url)
            .then((data) => {
                return data.json();
            })
            .then((result) => {
                setAddressInfo(result);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (newDeliveryAddress) {
            let address = addressSuggestions.find(
                (x) => x.label === newDeliveryAddress
            );
            if (address) {
                getAddressInfo(address.id);
            }
        }
    }, [addressSuggestions, newDeliveryAddress]);

    const handleNewEmailChange = useCallback(
        (value, index) => {
            let emails = [...newEmailAddresses];
            emails[index] = value;
            setNewEmailAddresses(emails);
        },
        [newEmailAddresses]
    );

    const createNewDeliveryPoint = useCallback(() => {
        if (newDeliveryPointError || !addressInfo) return;
        let addressData = {
            city: addressInfo.city,
            code: addressInfo.postcode,
            country: "New Zealand",
            latitude: addressInfo.x,
            longitude: addressInfo.y,
            street: addressInfo.numberfull + " " + addressInfo.street,
            suburb: addressInfo.suburb,
        };
        let emailData = [];
        newEmailAddresses.map((email) => {
            if (email.length > 0) {
                emailData.push({
                    email_address: email,
                });
            }
            return null;
        });
        insertDeliveryPoint({
            variables: {
                name: deliveryPointName,
                organisation_id: props.organisation.id,
                address_data: addressData,
                email_data: emailData,
                created: dayjs(),
            },
        }).then((r) => {
            console.log("delivery point inserted");
            setNewDeliveryPointDialog(false);
            refetchDeliveryPoints();
        });
    }, [
        props,
        insertDeliveryPoint,
        addressInfo,
        newDeliveryPointError,
        newEmailAddresses,
        deliveryPointName,
        refetchDeliveryPoints,
    ]);

    useEffect(() => {
        if (
            newEmailAddresses.length > 0 &&
            newEmailAddresses[newEmailAddresses.length - 1].length > 0
        ) {
            let emails = [...newEmailAddresses];
            emails.push("");
            setNewEmailAddresses(emails);
            return;
        }
        if (
            newEmailAddresses.length > 1 &&
            newEmailAddresses[newEmailAddresses.length - 2].length === 0
        ) {
            let emails = [...newEmailAddresses];
            emails.pop();
            setNewEmailAddresses(emails);
            return;
        }
    }, [newEmailAddresses]);

    useEffect(() => {
        let error = null;
        if (!deliveryPointName || deliveryPointName.length < 1) {
            error = "Please enter a delivery point name";
        }
        if (
            !newDeliveryAddress ||
            (addressInfo && newDeliveryAddress !== addressInfo.full)
        ) {
            error = "Enter an address";
        }
        for (let email in newEmailAddresses) {
            if (
                newEmailAddresses[email] &&
                newEmailAddresses[email].length > 0 &&
                !newEmailAddresses[email].includes("@")
            ) {
                error = "One of the supplied email addresses is not valid. ";
            }
        }
        setNewDeliveryPointError(error);
    }, [newEmailAddresses, newDeliveryAddress, addressInfo, deliveryPointName]);

    const createDataReceipt = useCallback(() => {
        if (!selectedMeasurementPoint) {
            setDataReceiptError("Please select a Sensor");
            return;
        }
        if (!selectedDeliveryPoint) {
            setDataReceiptError("Please select a delivery point");
            return;
        }
        setDataReceiptError(null);
        insertDataReceipt({
            variables: {
                data_receipt: {
                    delivery_point_id: selectedDeliveryPoint.id,
                    start_time: startTime,
                    end_time: endTime,
                    measurement_point_id: selectedMeasurementPoint.id,
                    name: `[${startTime.format("DD-MM-YYYY")}] ${props.site.name
                        }`,
                    organisation_id: props.organisation.id,
                    site_id: props.site.id,
                    user_id: props.user.id,
                    created: dayjs(),
                    sent: false
                },
            },
        }).then((r) => {
            setSuccessModal(true);
        });
    }, [
        insertDataReceipt,
        selectedDeliveryPoint,
        startTime,
        endTime,
        selectedMeasurementPoint,
        props.site,
        props.organisation,
        props.user,
        setDataReceiptError,
    ]);

    const closeSuccessModal = () => {
        setSuccessModal(false);
        props.refetch();
        props.setNewDelivery(false);
    };

    return (
        <div className="max-w-lg m-5 overflow-y-auto h-full pb-40">
            <DeliveryCompleteModal
                successModal={successModal}
                closeSuccessModal={closeSuccessModal}
                selectedDeliveryPoint={selectedDeliveryPoint}
                {...props}
            />
            <Dialog
                open={newDeliveryPointDialog}
                onClose={() => setNewDeliveryPointDialog(false)}
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="w-full max-w-sm rounded-lg bg-white p-5">
                        <Dialog.Title className={"pb-2 text-xl"}>
                            New Delivery Point
                        </Dialog.Title>
                        <div>Delivery Point Name</div>
                        <input
                            type="text"
                            placeholder="Enter name"
                            className="w-full p-2 rounded-md text-sm placeholder-gray-text text-gray-charcoal border-2 h-10"
                            name="address"
                            onChange={(e) =>
                                setDeliveryPointName(e.target.value)
                            }
                            required
                        />
                        <div className="mt-2">Delivery Address</div>
                        <Combobox
                            value={newDeliveryAddress}
                            onChange={setNewDeliveryAddress}
                        >
                            <Combobox.Input
                                placeholder="Enter address"
                                className="w-full p-2 rounded-md text-sm border-2 placeholder-gray-text text-gray-charcoal h-10"
                                onChange={(event) =>
                                    getAddresses(event.target.value)
                                }
                            />
                            <Combobox.Options className="mt-1 text-sm w-full bg-white rounded-md z-20 border space-y-1">
                                {addressSuggestions.map((address) => (
                                    <Combobox.Option
                                        className={`cursor-pointer select-none relative py-0.5 px-2 text-sm hover:bg-gray-50`}
                                        key={address.id}
                                        value={address.label}
                                    >
                                        {address.label}
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </Combobox>
                        <div className="mt-2">Notification Targets</div>

                        {newEmailAddresses.map((email, index) => {
                            return (
                                <input
                                    className="w-full p-2 rounded-md text-sm border-2 placeholder-gray-text text-gray-charcoal h-10 mb-1"
                                    key={index}
                                    placeholder={"Enter email"}
                                    onChange={(event) =>
                                        handleNewEmailChange(
                                            event.target.value,
                                            index
                                        )
                                    }
                                />
                            );
                        })}
                        <div className="flex flex-row justify-between w-full mt-5">
                            <CommonButton
                                width={"3/4"}
                                submit={false}
                                colour={props.organisation.theme.secondary}
                                title={"Cancel"}
                                onClick={() => setNewDeliveryPointDialog(false)}
                            />
                            <CommonButton
                                disabled={
                                    newDeliveryPointError !== null ||
                                    !addressInfo
                                }
                                width={"3/4"}
                                submit={false}
                                colour={props.organisation.theme.secondary}
                                title={"Save"}
                                onClick={createNewDeliveryPoint}
                            />
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
            <div
                className={classNames(
                    `text-${props.organisation.theme.secondary}`,
                    "text-2xl pb-2.5"
                )}
            >
                New Delivery
            </div>
            <div>Start time</div>
            <div className="flex flex-row justify-between pb-4">
                <div className="mr-1 w-full">
                    <DatePicker
                        title={false}
                        setDate={setStartTime}
                        value={startTime}
                    />
                </div>
                <div className="ml-1 w-full">
                    <TimePicker
                        title={false}
                        setTime={setStartTime}
                        value={startTime}
                    />
                </div>
            </div>
            <div>End time</div>
            <div className="flex flex-row justify-between pb-4">
                <div className="mr-1 w-full">
                    <DatePicker
                        title={false}
                        setDate={setEndTime}
                        value={endTime}
                    />
                </div>
                <div className="ml-1 w-full">
                    <TimePicker
                        title={false}
                        setTime={setEndTime}
                        value={endTime}
                    />
                </div>
            </div>
            <div>Measurement Point</div>
            <div className="pb-4">
                <ListBox
                    selectedOption={selectedMeasurementPoint}
                    placeholder="Select a measurement point"
                    itemLabel={(item) => `${item.name}`}
                    list={measurementPointOptions}
                    setSelectedOption={setSelectedMeasurementPoint}
                />
            </div>
            <div className="pb-1">Delivery Point</div>
            <CommonButton
                width={"full"}
                submit={false}
                colour={props.organisation.theme.secondary}
                title={"Create new delivery point"}
                onClick={() => setNewDeliveryPointDialog(true)}
            />
            <div className="text-center">or</div>
            <div className="pb-4 w-full">
                <ListBox
                    selectedOption={selectedDeliveryPoint}
                    placeholder="Select a delivery point"
                    itemLabel={(item) =>
                        `[${item.name}] ${item.address.street}, ${item.address.city}`
                    }
                    list={deliveryPointOptions}
                    setSelectedOption={setSelectedDeliveryPoint}
                />
            </div>
            {dataReceiptError && (
                <div className="text-red-700 flex flex-row justify-between">
                    {dataReceiptError}
                    <ExclamationCircleIcon className="w-5 h-5" />
                </div>
            )}
            <div className="flex flex-row justify-between w-full mt-5">
                <div className="mr-1 w-full">
                    <CommonButton
                        width={"full"}
                        submit={false}
                        colour={props.organisation.theme.secondary}
                        title={"Cancel"}
                        onClick={() => props.setNewDelivery(false)}
                    />
                </div>
                <div className="ml-1 w-full">
                    <CommonButton
                        width={"full"}
                        submit={false}
                        colour={props.organisation.theme.secondary}
                        title={"Send Report"}
                        onClick={createDataReceipt}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewDelivery;
