import { PencilAltIcon } from "@heroicons/react/outline";
import React, { useState, useMemo } from "react";
import { useTable } from "react-table";
import NotesModal from "../NotesModal/NotesModal";

const WIDTHS = {
    Asset: "col-start-1 col-span-2",
    Description: "col-start-3 col-span-5",
    "Description / Summary": "col-start-3 col-span-5",
    Updated: "col-start-8 col-span-2",
    Status: "col-start-10 col-span-1",
    Notes: "col-start-11 col-span-1 justify-self-end",
};

const WIDTHS_MOBILE = {
    Asset: "col-start-1 col-span-2",
    Description: "col-start-3 col-span-4",
    "Description / Summary": "col-start-3 col-span-5",
    Updated: "col-start-7 col-span-2",
    Status: "col-start-9 col-span-2",
    Notes: "col-start-11 col-span-1 justify-self-end",
};

const IssuesTable = (props) => {
    const columns = useMemo(() => props.titles, [props.titles]);
    const data = useMemo(() => props.data, [props.data]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState();

    const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
        useTable({
            columns,
            data,
        });

    return (
        <table {...getTableProps()} className="w-full">
            <NotesModal
                open={openModal}
                close={setOpenModal}
                title="Notes"
                issue={selectedIssue}
                {...props}
            />
            <thead className="lg:text-base text-2xs">
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="grid grid-cols-11 justify-items-start py-1 lg:py-2"
                    >
                        {headerGroup.headers.map((column) => {
                            if (column.id === "notes") {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="col-start-11 col-span-1 justify-self-end sm:pr-4 pr-1"
                                    >
                                        {column.render("Header")}
                                        <div {...column.getHeaderProps()}></div>
                                    </th>
                                );
                            } else {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        className={`${
                                            window.innerWidth > 640
                                                ? WIDTHS[column.Header]
                                                : WIDTHS_MOBILE[column.Header]
                                        } border-l-4 w-full border-white flex flex-row`}
                                    >
                                        {column.render("Header")}
                                        <div {...column.getHeaderProps()}></div>
                                    </th>
                                );
                            }
                        })}
                    </tr>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
                className={`lg:text-base text-2xs w-full text-${props.organisation.theme.primary}`}
            >
                <div className="max-h-60 overflow-y-auto w-full">
                    {rows.length > 0 ? (
                        rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className={
                                        "grid grid-cols-11 justify-items-start py-2 border-t items-center"
                                    }
                                    onClick={() => {
                                        setSelectedIssue(row.original.id);
                                    }}
                                >
                                    {row.cells.map((cell) => {
                                        if (cell.column.id === "notes") {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="flex flex-row justify-self-end pr-1 sm:pr-4 sm:gap-x-4 gap-x-1"
                                                >
                                                    <div>
                                                        {cell.render("Cell")}
                                                    </div>
                                                    <div
                                                        className="sm:w-5 sm:h-5 w-4 h-4 cursor-pointer"
                                                        onClick={() =>
                                                            setOpenModal(true)
                                                        }
                                                    >
                                                        <PencilAltIcon />
                                                    </div>
                                                </td>
                                            );
                                        } else if (
                                            row.original.resolution &&
                                            cell.column.id === "description"
                                        ) {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={`${
                                                        window.innerWidth > 640
                                                            ? WIDTHS[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                            : WIDTHS_MOBILE[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                    }`}
                                                >
                                                    <div className="lg:text-base text-2xs font-semibold">
                                                        {
                                                            row.original
                                                                .resolution
                                                        }
                                                    </div>
                                                    <div className="lg:text-2xs text-3xs">
                                                        {window.innerWidth > 640
                                                            ? `${row.original.description} / ${row.original.summary}`
                                                            : row.original
                                                                  .description}
                                                    </div>
                                                </td>
                                            );
                                        } else if (
                                            !row.original.resolution &&
                                            cell.column.id === "description"
                                        ) {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={`${
                                                        window.innerWidth > 640
                                                            ? WIDTHS[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                            : WIDTHS_MOBILE[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                    }`}
                                                >
                                                    {window.innerWidth > 640
                                                        ? `${row.original.description} / ${row.original.summary}`
                                                        : row.original
                                                              .description}
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={`${
                                                        window.innerWidth > 640
                                                            ? WIDTHS[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                            : WIDTHS_MOBILE[
                                                                  cell.column
                                                                      .Header
                                                              ]
                                                    }`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        <div className="text-center text-gray-600 py-5">
                            No Issues Yet
                        </div>
                    )}
                </div>
            </tbody>
        </table>
    );
};
export default IssuesTable;
