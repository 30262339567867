import dayjs from "dayjs";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

//function to format time raised"
export const handleTime = (time) => {
    let current = dayjs();
    const formattedTime = dayjs(time);
    if (current.diff(formattedTime, "seconds") < 60) {
        return (
            dayjs.duration(current.diff(formattedTime)).asSeconds().toFixed(0) +
            " seconds ago"
        );
    } else if (current.diff(formattedTime, "minutes") < 60) {
        return (
            dayjs.duration(current.diff(formattedTime)).format("m") +
            " minutes ago"
        );
    } else if (current.diff(formattedTime, "hours") < 24) {
        if (
            current.diff(dayjs().startOf("day"), "seconds") >
            current.diff(formattedTime, "seconds")
        ) {
            return "Today at " + dayjs(time).format("H:mm");
        } else {
            return "Yesterday at " + dayjs(time).format("H:mm");
        }
    } else {
        return dayjs(time).format("YYYY-MM-DD H:mm");
    }
};
