import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
} from "amazon-cognito-identity-js";

const authenticateUser = (username, password) => {
    return new Promise((resolve, reject) => {
        let authenticationData = {
            Username: username,
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(
            authenticationData
        );
        let userPool = createCognitoUserPool();
        let userData = {
            Username: username,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                const session = {
                    credentials: {
                        accessToken: result.accessToken.jwtToken,
                        idToken: result.idToken.jwtToken,
                        refreshToken: result.refreshToken.token,
                    },
                    user: {
                        userName: result.idToken.payload["cognito:username"],
                        email: result.idToken.payload.email,
                    },
                };
                resolve(session);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    });
};

// add a new user to the user pool
const signUpNewUser = (email, password, phoneNumber, firstName, lastName) => {
    return new Promise((resolve, reject) => {
        const pool = createCognitoUserPool();
        pool.signUp(
            email,
            password,
            [
                {
                    Name: "name",
                    Value: firstName + " " + lastName,
                },
                {
                    Name: "given_name",
                    Value: firstName,
                },
                {
                    Name: "family_name",
                    Value: lastName,
                },
            ],
            [],
            (err, result) => {
                if (result) {
                    resolve(result);
                }
                if (err) {
                    reject(err);
                }
            }
        );
    });
};

const forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
        let userPool = createCognitoUserPool();
        let userData = {
            Username: username,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function (result) {
                resolve(result);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    });
};

// confirm the verification code and set new password
const confirmPassword = (username, verificationCode, newPassword) => {
    let userPool = createCognitoUserPool();
    let cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure(err) {
                reject(err);
            },
            onSuccess(result) {
                resolve(result);
            },
        });
    });
};

// Creates a CognitoUser instance
const createCognitoUser = () => {
    const pool = createCognitoUserPool();
    return pool.getCurrentUser();
};

// Creates a CognitoUserPool instance
const createCognitoUserPool = () =>
    new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = () => {
    const signinUri = `https://${process.env.REACT_APP_COGNITO_POOL_BASE_URL}/login?response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_CALLBACK_URL}`;
    return signinUri;
};

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = createCognitoUser();
        if (cognitoUser === null) reject();
        cognitoUser.getSession((err, result) => {
            if (err || !result) {
                reject(new Error("Failure getting Cognito session: " + err));
                console.log("Failure getting session: " + JSON.stringify(err));
                return;
            }
            const session = {
                credentials: {
                    accessToken: result.accessToken.jwtToken,
                    idToken: result.idToken.jwtToken,
                    refreshToken: result.refreshToken.token,
                },
                user: {
                    userName: result.idToken.payload["cognito:username"],
                    email: result.idToken.payload.email,
                },
            };
            resolve(session);
        });
    });
};

const confirmRegistration = (username, verificationCode) => {
    let userPool = createCognitoUserPool();
    let cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(
            verificationCode,
            true,
            (error, result) => {
                if (result) resolve(result);
                if (error) reject(error);
            }
        );
    });
};

const resendConfirmationCode = (username) => {
    let userPool = createCognitoUserPool();
    let cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((error, result) => {
            if (result) resolve(result);
            if (error) reject(error);
        });
    });
};

const signOutCognitoSession = () => {
    const cognitoUser = createCognitoUser();
    if (cognitoUser !== null) {
        cognitoUser.signOut();
    }
    window.location = "/login";
};

const utils = {
    createCognitoUser,
    createCognitoUserPool,
    getCognitoSession,
    getCognitoSignInUri,
    signOutCognitoSession,
    authenticateUser,
    signUpNewUser,
    forgotPassword,
    confirmPassword,
    confirmRegistration,
    resendConfirmationCode,
};

export default utils;
