import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationIcon,
} from "@heroicons/react/solid";
import React from "react";
import ValueIndicator from "../ValueIndicator";

const AirQualityPanel = (props) => {
    const co2 = props.data ? props.data.co2_concentration : "N/A";
    return (
        <div className=" h-24 sm:h-32 bg-white rounded-md sm:p-3 p-2 flex flex-col justify-between max-w-xl">
            <div className="text-gray-700 sm:text-lg text-sm">{props.name}</div>
            <div className="flex flex-row lg:gap-x-20 sm:gap-x-16 sm:justify-start justify-between">
                {co2 < 1000 && (
                    <div className="flex flex-col gap-y-1 sm:gap-y-2 pt-1 sm:pt-2 font-semibold">
                        <CheckCircleIcon className="w-6 h-6 sm:w-7 sm:h-7 text-green-400 self-center" />
                        <div className="text-gray-text font-semibold text-xs">
                            Air Change Rate
                        </div>
                    </div>
                )}
                {co2 >= 1000 && co2 <= 1500 && (
                    <div className="flex flex-col gap-y-1 sm:gap-y-2 pt-1 sm:pt-2 font-semibold">
                        <ExclamationIcon className="w-6 h-6 sm:w-7 sm:h-7 text-yellow-400 self-center" />
                        <div className="text-gray-text font-semibold text-xs">
                            Air Change Rate
                        </div>
                    </div>
                )}
                {co2 > 1500 && (
                    <div className="flex flex-col gap-y-1 sm:gap-y-2 pt-1 sm:pt-2 font-semibold">
                        <ExclamationCircleIcon className="w-6 h-6 sm:w-7 sm:h-7 text-red-500 self-center animate-ping" />
                        <div className="text-gray-text font-semibold text-xs">
                            Air Change Rate
                        </div>
                    </div>
                )}
                <ValueIndicator name="CO2" data={co2} color={props.colors} />
                <ValueIndicator
                    name="Temperature"
                    data={props.data ? props.data.temperature : "N/A"}
                    color={props.colors}
                />
                <ValueIndicator
                    name="Humidity"
                    data={props.data ? props.data.relative_humidity : "N/A"}
                    color={props.colors}
                />
            </div>
        </div>
    );
};

export default AirQualityPanel;
