import * as SolidHeroIcons from "@heroicons/react/solid";
import * as OutlineHeroIcons from "@heroicons/react/outline";

import classNames from "classnames";

const DynamicHeroIcon = (props) => {
    const { ...solidIcons } = SolidHeroIcons;
    const { ...outlineIcons } = OutlineHeroIcons;

    let Icon = solidIcons[props.icon];

    if (props.outline) {
        Icon = outlineIcons[props.icon];
    }

    let width = props.width;
    let height = props.height;
    let color = props.color;

    if (!width) {
        width = 5;
    }
    if (!height) {
        height = 5;
    }
    if (!color) {
        color = "white";
    }

    return (
        <Icon
            className={classNames(`h-${height} w-${width} text-${color}`)}
            aria-hidden="true"
        />
    );
};

export default DynamicHeroIcon;
