import React from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import DisplayValue from "../DisplayValue/DisplayValue";

const IssueOverviewPanel = (props) => {
    return (
        <div className={`w-full bg-white rounded-md px-2 py-1 border`}>
            <div className="flex justify-between">
                <div
                    className={`text-${props.theme.secondary}  sm:text-xl text-lg`}
                >
                    {props.title}
                </div>
                {props.issueCount > 0 ? (
                    <ExclamationCircleIcon className="w-4 h-4 m-2 motion-safe:animate-ping text-red-600" />
                ) : (
                    <CheckCircleIcon className="w-5 h-5 sm:w-7 sm:h-7 text-green-400 " />
                )}
            </div>

            <div className="flex flex-row justify-evenly">
                <DisplayValue
                    title={`${props.type === "Local" ? "Local" : ""} Issue${
                        props.issueCount === 1 ? "" : "s"
                    } Needing ${
                        props.type === "Local"
                            ? "Attention"
                            : "External Servicing"
                    }`}
                    color={props.theme.primary}
                    value={props.issueCount.toFixed(0)}
                />
            </div>
        </div>
    );
};

export default IssueOverviewPanel;
