import React from "react";
import dayjs from "dayjs";

const TimePicker = (props) => {
    if (props === undefined) return <div></div>;

    const handleInputChange = (event) => {
        let split = event.target.value.split(":");
        let hour = split[0];
        let minute = split[1];
        event.persist();
        props.setTime((date) => {
            date = date.hour(hour);
            date = date.minute(minute);
            return date;
        });
    };
    return (
        <div className="w-full">
            {props.title && <div className="pb-1">Time</div>}

            <div className="relative">
                {/* <span className="absolute inset-y-0 right-0 flex items-center">
                    <button
                        id="timeIcon"
                        type="submit"
                        className="p-1 focus:outline-none focus:shadow-outline cursor-default text-gray-text"
                        disabled
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </button>
                </span> */}
                <input
                    type="time"
                    name="time"
                    value={props.value.format("HH:mm")}
                    placeholder={dayjs().format("h:mm A")}
                    className="rounded-md  border-0 text-sm h-10 p-2 w-full"
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    onFocus={(e) => {
                        e.currentTarget.type = "time";
                        if (document.getElementById("timeIcon") !== null) {
                            document.getElementById("timeIcon").remove();
                        }
                        e.currentTarget.focus();
                    }}
                ></input>
            </div>
        </div>
    );
};

export default TimePicker;
