import React, { useState, useCallback, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { gql, useQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { handleTime } from "../../utils/helper";
import { XCircleIcon } from "@heroicons/react/outline";

const MUTATION_INSERT_NOTE = gql`
    mutation InsertNote(
        $time: timestamptz!
        $issueId: Int!
        $description: String!
        $authorId: Int!
    ) {
        insert_ppcs_core_notes_one(
            object: {
                time: $time
                issue_id: $issueId
                description: $description
                author_id: $authorId
            }
        ) {
            id
            author_id
            description
            issue_id
            time
        }
    }
`;

const QUERY_NOTES_BY_ISSUE = gql`
    query getNotesByIssue($issueId: Int!) {
        issue_by_pk(id: $issueId) {
            id
            notes(order_by: { time: desc }) {
                id
                description
                time
                user {
                    id
                    email
                    first_name
                    last_name
                }
            }
        }
    }
`;
const NotesModal = (props) => {
    const [noteInput, setNoteInput] = useState("");
    const [notes, setNotes] = useState([]);
    const [insertNote] = useMutation(MUTATION_INSERT_NOTE);

    const {
        data: notesData,
        error: notesError,
        refetch: refetchNotes,
    } = useQuery(QUERY_NOTES_BY_ISSUE, {
        variables: {
            issueId: props.issue,
        },
        onError: () => console.log(notesError),
        skip: !props.issue,
    });

    useEffect(() => {
        if (!notesData) return;
        setNotes(notesData.issue_by_pk.notes);
    }, [notesData]);

    const handleSubmit = useCallback(() => {
        if (!props.issue || noteInput === "") return;
        insertNote({
            variables: {
                authorId: props.user.id,
                time: dayjs(),
                issueId: props.issue,
                description: noteInput,
            },
        })
            .then((r) => {
                console.log("Note Submitted");
                refetchNotes();
                setNoteInput("");
            })
            .catch((e) => console.log(e));
    }, [props, noteInput, insertNote, refetchNotes]);

    return (
        <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => props.close(false)}
            open={props.open}
        >
            <div className="min-h-screen px-4 text-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div className="inline-block sm:w-120 p-3 sm:p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl text-blueGray-800">
                    <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading flex flex-row justify-between"
                    >
                        {props.title ? props.title.toUpperCase() : ""}
                        <div
                            className="w-7 h-7 cursor-pointer"
                            onClick={() => props.close(false)}
                        >
                            <XCircleIcon />
                        </div>
                    </Dialog.Title>
                    <div className="mt-4">
                        <div className="grid grid-cols-4 border-b mb-2 max-h-40 overflow-y-auto">
                            <div className="col-start-1 col-span-2">Note</div>
                            <div className="col-start-3 col-span-1">Author</div>
                            <div className="col-start-4 col-span-1 justify-self-end">
                                Time
                            </div>
                        </div>
                        {notes.length > 0 ? (
                            notes.map((note) => {
                                return (
                                    <div className="grid grid-cols-4 text-xs pb-2 gap-x-1">
                                        <div className="col-start-1 col-span-2">
                                            {note.description}
                                        </div>
                                        <div className="col-start-3 col-span-1">
                                            {`${
                                                note.user
                                                    ? note.user.first_name
                                                    : "-"
                                            } ${
                                                note.user
                                                    ? note.user.last_name
                                                    : "-"
                                            }`}
                                        </div>
                                        <div className="col-start-4 col-span-1 justify-self-end">
                                            {handleTime(note.time)}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="py-2 mb-2 text-center text-gray-400">
                                No Notes Yet...
                            </div>
                        )}
                        <div className="border-b" />

                        <div className="text-sm pt-4 pl-1">Add a note</div>
                        <textarea
                            onChange={(e) => setNoteInput(e.target.value)}
                            value={noteInput}
                            type="text"
                            placeholder="Start typing note..."
                            className="w-full px-2 py-2 rounded-md text-sm border-2 placeholder-gray-text text-gray-charcoal"
                        />
                    </div>
                    <div className="flex flex-row gap-x-5">
                        <div
                            onClick={handleSubmit}
                            className={`rounded-md py-2 px-2 w-16 text-center mt-2 cursor-pointer bg-${props.organisation.theme.secondary} text-white sm:text-base text-sm`}
                        >
                            SAVE
                        </div>
                        <div
                            className={`rounded-md py-2 px-2 w-16 text-center cursor-pointer mt-2 bg-${props.organisation.theme.secondary} text-white sm:text-base text-sm`}
                            onClick={() => setNoteInput("")}
                        >
                            CLEAR
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default NotesModal;
