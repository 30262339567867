import React, { useEffect, useState, useCallback } from "react";
import ListBox from "../FormComponents/ListBox";
import { gql, useQuery, useMutation } from "@apollo/client";
import CommonButton from "../CommonButton";
import dayjs from "dayjs";
import { getQueryParameter } from "../../utils/queryParams";
import { getUserSession } from "../../utils/userSession";
import { useHistory } from "react-router";
import classNames from "classnames";

const GET_ASSET_BY_ZONE = gql`
  query getAssetByZone($siteId: Int!) {
    site_by_pk(id: $siteId) {
      zones {
        assets {
          description
          id
          zone {
            id
          }
        }
        name
      }
    }
  }
`;
const MUTATION_SUBMIT_REQUEST = gql`
  mutation createRequest($object: request_insert_input!) {
    insert_request_one(object: $object) {
      asset_id
      description
      details
      open
      organisation_id
    }
  }
`;

const QUERY_USER_ID = gql`
  query getUserId($email: String!) {
    user_profile(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

const RequestForm = (props) => {
  const [assetList, setAssetList] = useState([
    { id: 0, name: "Select an asset", unavailable: true, zone: "" },
  ]);
  const [assetId, setAssetId] = useState();
  const [assetZoneId, setAssetZoneId] = useState();
  const [formData, setFormData] = useState({
    description: "",
    details: "",
  });
  // const [time] = useState({ time: dayjs().format("h:mm A") });
  // const [date] = useState({
  //     date: dayjs().format("ddd D MMM YYYY"),
  // });
  const [priority] = useState(false);
  const [userEmail] = useState(getUserSession().user.email);
  const [userId, setUserId] = useState();
  const history = useHistory();
  const [isComplete, setIsComplete] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState();
  const siteId = getQueryParameter("siteId");
  const orgId = getQueryParameter("organisationId");
  //mutation
  const [createRequest] = useMutation(MUTATION_SUBMIT_REQUEST);

  const handleInputChange = (event) => {
    event.persist();
    setIsComplete(true);
    setFormData((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  //get list of all assets for a zone
  const { data: assetData, error: assetDataError } = useQuery(
    GET_ASSET_BY_ZONE,
    {
      fetchPolicy: "network-only",
      variables: {
        siteId: siteId,
      },
      onError: () => {
        console.log(assetDataError);
      },
    }
  );

  const { data: userIdData, error: userIdError } = useQuery(QUERY_USER_ID, {
    variables: {
      email: userEmail,
    },
    onError: () => console.log(userIdError),
  });

  useEffect(() => {
    if (userIdData === undefined || userIdData.user_profile.length < 1) return;
    setUserId(userIdData.user_profile[0].id);
  }, [userIdData]);

  useEffect(() => {
    if (
      assetData === undefined ||
      assetData.site_by_pk === undefined ||
      assetData.site_by_pk.zones === undefined
    )
      return;
    let assets = [];
    assetData.site_by_pk.zones.map((zone) => {
      zone.assets.map((asset) => {
        return assets.push({
          zone: zone.name,
          id: asset.id,
          name: asset.description,
          unavailable: false,
          zoneId: asset.zone.id,
        });
      });
      return assets;
    });
    setAssetList(assets);
  }, [assetData]);

  const handleSubmit = useCallback(() => {
    if (
      formData.description === "" ||
      formData.details === "" ||
      assetId === undefined
    ) {
      setIsComplete(false);
      return;
    } else {
      let requestData = {
        asset_id: assetId,
        description: formData.description,
        details: formData.details,
        time: dayjs().format(),
        priority: priority,
        reporter_id: userId,
        organisation_id: props.organisation.id,
        zone_id: assetZoneId,
        updated_at: dayjs().format(),
        open: true,
      };
      createRequest({
        variables: {
          object: requestData,
        },
      })
        .then((r) => {
          console.log("request created!");
          setIsComplete(true);
          history.push(
            `/requests/?submitted=true/?organisationId=${orgId}&siteId=${siteId}`
          );
        })
        .catch((e) => {});
    }
  }, [
    formData,
    assetId,
    priority,
    userId,
    props.organisation,
    assetZoneId,
    createRequest,
    orgId,
    history,
    siteId,
  ]);

  useEffect(
    (item) => {
      if (!selectedAsset) return;
      setAssetId(selectedAsset.id);
      setAssetZoneId(selectedAsset.zoneId);
    },
    [selectedAsset]
  );

  if (assetList[0] === undefined) return <div>404</div>;
  if (assetList[0].name === "Select an asset") return <div></div>;

  return (
    <div>
      <label className="text-sm text-gray-charcoal">Asset</label>
      <br />

      <div className="sm:w-96 w-72 pb-5">
        <ListBox
          selectedOption={selectedAsset}
          placeholder="Select an Asset"
          itemLabel={(item) => `[${item.zone}] ${item.name}`}
          list={assetList}
          setSelectedOption={setSelectedAsset}
        />
      </div>
      <div className="text-sm  text-gray-text mb-5">
        <div className="text-gray-charcoal pb-1">Description</div>
        <div>
          <input
            type="text"
            placeholder="Describe your request in as few words as possible"
            className="sm:w-96 w-72 px-2 py-2 rounded-md text-sm border-0 placeholder-gray-text text-gray-charcoal"
            name="description"
            onChange={handleInputChange}
            required
          ></input>
        </div>
        <div className="text-gray-charcoal pt-5 pb-1">Request Details</div>
        <textarea
          rows="10"
          cols="50"
          placeholder="Tell us more about the request e.g. when you first noticed it,
                how often it is recurring etc."
          name="details"
          className="rounded-md text-sm sm:w-96 w-72 h-32 pt-2 px-2 border-0 placeholder-gray-text text-gray-charcoal"
          onChange={handleInputChange}
          required
        ></textarea>
      </div>
      {/* <ToggleSwitch setPriority={setPriority} title="Is It Urgent?" /> */}
      {!isComplete && (
        <div className={classNames(`text-${props.color}`, "text-sm pb-4")}>
          Please fill in all fields
        </div>
      )}
      <CommonButton
        onClick={handleSubmit}
        title="SUBMIT"
        width="32"
        colour={props.organisation.theme.secondary}
      />
    </div>
  );
};

export default RequestForm;
