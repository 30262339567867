import React, { useEffect, useState } from "react";
import zLogo from "../../assets/images/z_logo.png";
import BNZLogo from "../../assets/images/BNZ-logo.png";
import DynamicHeroIcon from "../DynamicHeroIcon/DynamicHeroIcon";

const logos = {
    "z_logo.png": zLogo,
    "BNZ-logo.png": BNZLogo,
};

const OrgInfo = (props) => {
    const [isMobile, setIsMobile] = useState();
    useEffect(() => {
        if (window.innerWidth > 640 && window.innerHeight > 640)
            setIsMobile(false);
        else setIsMobile(true);
    }, []);

    return (
        <div
            className={
                isMobile
                    ? "flex space-x-4 px-4"
                    : "flex flex-col pt-5 px-0 space-x-0"
            }
        >
            {logos[props.logo] !== undefined ? (
                <img
                    src={logos[props.logo]}
                    alt=""
                    className={
                        isMobile
                            ? "w-8 h-8 self-center left-4"
                            : "w-16 h-16 self-center left-4"
                    }
                />
            ) : (
                <div className="self-center ">
                    <DynamicHeroIcon
                        icon="OfficeBuildingIcon"
                        width={8}
                        height={8}
                        outline
                    />
                </div>
            )}

            <div
                className={
                    isMobile
                        ? "text-sm self-center text-white"
                        : "text-lg pt-5 self-center text-white px-5"
                }
            >
                {props.name}
            </div>
        </div>
    );
};

export default OrgInfo;
