import React, { useState, useEffect } from "react";
import SideBar from "../../components/SideBar/SideBar";
import Home from "../Home/Home";
import { Switch, Route } from "react-router-dom";
import Issues from "../Requests";
import ReportIssue from "../ReportIssue";
import {
    clearSession,
    isUserLoggedIn,
    refreshAccessToken,
    updateAccessToken,
    getUserEmail,
} from "../../utils/userSession";
import cognitoUtils from "../../utils/cognito";
import { gql, useQuery, useMutation } from "@apollo/client";
import MobileNav from "../../components/MobileNav/MobileNav";
import Deliveries from "../Deliveries";
import { useHistory } from "react-router-dom";
import OverView from "../Overview/Overview";
import { getQueryParameter } from "../../utils/queryParams";
import dayjs from "dayjs";
import { deviceDetect } from "react-device-detect";
import PullToRefresh from "react-simple-pull-to-refresh";
import { RefreshIcon } from "@heroicons/react/outline";
import PermissionDenied from "../PermissionDenied";
import { decode } from "base-64";
import { getUserSession } from "../../utils/userSession";
import OrganisationSiteSelector from "../../components/OrganisationSiteSelector";
import Draggable from "react-draggable";
import Settings from "../Settings/Settings";

export const DEFAULT_COLORS = {
    primary: "blueGray-800",
    secondary: "teal-500",
    active: "blueGray-700",
};

const logOut = () => {
    cognitoUtils.signOutCognitoSession();
    clearSession();
};

const UPDATE_SESSION_HISTORY_MUTATION = gql`
    mutation insertSessionHitory(
        $application: String!
        $user_id: Int!
        $browser: String!
        $url: String!
        $time: timestamptz!
        $device: String!
    ) {
        insert_ppcs_core_usersessionhistory_one(
            object: {
                application: $application
                browser: $browser
                device: $device
                time: $time
                url: $url
                user_id: $user_id
            }
        ) {
            id
        }
    }
`;

const USER_ORGANISATION_QUERY = gql`
    query getOrganisation($email: String!) {
        user_profile(where: { email: { _eq: $email } }) {
            id
            first_name
            last_name
            email
            organisation {
                logo_image_filename
                name
                id
                theme
                sites {
                    id
                    name
                    short_name
                }
                subscriptions {
                    id
                    service
                }
            }
        }
    }
`;

const QUERY_ACCESSIBLE_ORGANISATIONS_SITES = gql`
    query getOrganisations {
        organisation(order_by: { name: asc }) {
            id
            name
            theme
            logo_image_filename
            sites {
                id
                name
                short_name
                organisation_id
            }
            subscriptions {
                id
                service
            }
        }
    }
`;

const decodeTokenPayload = (token) => {
    let base64 = token.split(".")[1];
    return JSON.parse(decode(base64));
};

const Base = () => {
    const isMobile =
        window.innerHeight > 640 && window.innerWidth > 640 ? false : true;
    const [organisation, setOrganisation] = useState();
    const [selectedSite, setSelectedSite] = useState();
    const [organisationOptions, setOrganisationOptions] = useState();
    const [allSites, setAllSites] = useState();
    const [siteOptions, setSiteOptions] = useState();
    const [user, setUser] = useState();
    const history = useHistory();
    const [isAdmin, setIsAdmin] = useState(false);

    const currentPage = history.location.pathname.replaceAll("/", "");
    const siteId = getQueryParameter("siteId");
    const currentUrl = window.location.href;

    const [insertSessionHistory] = useMutation(UPDATE_SESSION_HISTORY_MUTATION);

    if (!isUserLoggedIn()) {
        refreshAccessToken()
            .then(() => {
                updateAccessToken()
                    .then(() => {
                        if (!isUserLoggedIn()) {
                            logOut();
                        }
                        refetchUserOrgData();
                    })
                    .catch((e) => {
                        console.log(e);
                        logOut();
                    });
            })
            .catch((e) => {
                console.log(e);
                logOut();
            });
    }
    const { data: allOrganisations, error: organisationsError } = useQuery(
        QUERY_ACCESSIBLE_ORGANISATIONS_SITES,
        {
            onError: () => console.log(organisationsError),
        }
    );

    const {
        error: getUserError,
        data: organisationData,
        refetch: refetchUserOrgData,
    } = useQuery(USER_ORGANISATION_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            email: getUserEmail(),
        },
        onError: () => console.log(getUserError),
    });

    useEffect(() => {
        if (!organisationData || organisation) return;
        if (!organisationData.user_profile[0]) {
            logOut();
            return;
        }
        setUser({
            id: organisationData.user_profile[0].id,
            firstName: organisationData.user_profile[0].first_name,
            lastName: organisationData.user_profile[0].last_name,
            email: organisationData.user_profile[0].email,
        });
        if (!organisationData.user_profile[0].organisation) {
            return <PermissionDenied />;
        }
        setOrganisation({
            ...organisationData.user_profile[0].organisation,
            theme: organisationData.user_profile[0].organisation.theme
                ? organisationData.user_profile[0].organisation.theme
                : DEFAULT_COLORS,
        });
    }, [organisationData, organisation]);

    useEffect(() => {
        let user = getUserSession();
        if (!user || !user.credentials) return;
        let groups = decodeTokenPayload(user.credentials.idToken)[
            "cognito:groups"
        ];
        let access = -1;
        if (groups !== undefined)
            access = groups.findIndex((a) => a === "admin");
        if (access === -1) setIsAdmin(false);
        else setIsAdmin(true);
    }, []);

    useEffect(() => {
        if (!allOrganisations) return;
        let orgs = [];
        let sites = [];
        allOrganisations.organisation.map((org) => {
            orgs.push({
                ...org,
                id: org.id,
                name: org.name,
            });
            return org.sites.map((site) => {
                return sites.push(site);
            });
        });
        setOrganisationOptions(orgs);
        setAllSites(sites);
    }, [allOrganisations]);

    useEffect(() => {
        if (!user) return;
        const deviceInfo = deviceDetect();
        insertSessionHistory({
            variables: {
                application: "SHOP",
                time: dayjs(),
                user_id: user.id,
                url: currentUrl,
                browser: `${deviceInfo.browserName} - ${deviceInfo.browserFullVersion}`,
                device: `${deviceInfo.osName} - ${deviceInfo.osVersion}`,
            },
        }).then((r) => {
            console.log("added session history");
        });
    }, [currentUrl, insertSessionHistory, user]);

    useEffect(() => {
        if (!organisation || !allSites) return;
        let filteredSites = allSites.filter(
            (site) => site.organisation_id === parseInt(organisation.id)
        );
        setSiteOptions(filteredSites);
        if (!selectedSite) {
            let site = organisation.sites[0];
            history.push(
                `/${currentPage ? currentPage : "overview"}/?organisationId=${
                    organisation.id
                }&siteId=${site.id}`
            );
            setSelectedSite(site);
        }
    }, [history, organisation, selectedSite, currentPage, siteId, allSites]);

    if (!selectedSite) return <PermissionDenied />;
    return (
        <div
            className={
                window.innerWidth > 640 && window.innerHeight > 640
                    ? "flex flex-row"
                    : "flex flex-col"
            }
        >
            {window.innerWidth > 640 && window.innerHeight > 640 ? (
                <SideBar
                    organisation={organisation}
                    site={selectedSite}
                    user={user}
                />
            ) : (
                <div>
                    <MobileNav
                        organisation={organisation}
                        site={selectedSite}
                        user={user}
                    />
                </div>
            )}
            <div
                className={
                    !isMobile
                        ? "flex w-full xl:pl-72 lg:pl-56 pl-48 pt-5 h-screen bg-gray-bg"
                        : "w-screen flex h-screen overflow-y-auto bg-gray-bg pt-5 px-4"
                }
            >
                <Switch>
                    <PullToRefresh
                        onRefresh={() => window.location.reload()}
                        isPullable={isMobile}
                        pullDownThreshold={40}
                        maxPullDownDistance={40}
                        resistance={5}
                        className="absolute"
                        pullingContent={
                            <div
                                className={`flex flex-row justify-center pb-2 text-${organisation.theme.primary}`}
                            >
                                <RefreshIcon className="w-8 h-8 animate-spin" />
                            </div>
                        }
                        refreshingContent={
                            <div
                                className={`flex flex-row justify-center pb-2 text-${organisation.theme.primary}`}
                            >
                                <RefreshIcon className="w-8 h-8 animate-spin" />
                            </div>
                        }
                    >
                        {isAdmin &&
                            organisationOptions &&
                            (!isMobile ||
                                (isMobile &&
                                    window.location.href.includes(
                                        "settings"
                                    ))) && (
                                <Draggable disabled={isMobile}>
                                    <div className="absolute sm:right-0 pl-48 sm:pl-0 sm:pr-80 z-99">
                                        <OrganisationSiteSelector
                                            organisations={organisationOptions}
                                            sites={siteOptions}
                                            setOrg={setOrganisation}
                                            setSite={setSelectedSite}
                                        />
                                    </div>
                                </Draggable>
                            )}
                        <Route exact path={["/", "/home"]}>
                            <Home organisation={organisation} user={user} />
                        </Route>
                        <Route path="/overview">
                            <div className="h-full overflow-y-auto">
                                <OverView
                                    organisation={organisation}
                                    user={user}
                                />
                            </div>
                        </Route>
                        <Route path="/requests">
                            <Issues organisation={organisation} user={user} />
                        </Route>
                        <Route path="/deliveries">
                            <Deliveries
                                organisation={organisation}
                                site={selectedSite}
                                user={user}
                            />
                        </Route>
                        <Route path="/submitRequest">
                            <ReportIssue
                                organisation={organisation}
                                user={user}
                            />
                        </Route>
                        <Route path="/settings">
                            <Settings organisation={organisation} />
                        </Route>
                    </PullToRefresh>
                </Switch>
            </div>
        </div>
    );
};

export default Base;
