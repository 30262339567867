import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";
import IssueOverviewPanel from "../../components/IssueOverviewPanel";
import AirQualityPanel from "../../components/AirQualityPanel";
import IssuesTable from "../../components/IssuesTable";
import { handleTime } from "../../utils/helper";

const QUERY_OPEN_ISSUES = gql`
  query getOpenIssues($siteId: Int!) {
    site_by_pk(id: $siteId) {
      id
      local: issues(
        where: { status: { _lte: 2 }, locally_resolvable: { _eq: true } }
      ) {
        id
        asset {
          id
          description
        }
        description
        status
        summary
        steps_to_resolve
        time_raised
        issue_states(limit: 1, order_by: { time: desc_nulls_last }) {
          id
          time
          type
          value
        }
        notes(order_by: { time: desc }) {
          id
          description
          time
          user {
            id
            email
          }
        }
      }
      external: issues(
        where: { status: { _lte: 2 }, locally_resolvable: { _eq: false } }
      ) {
        id
        asset {
          id
          description
        }
        description
        status
        summary
        time_raised
        issue_states(limit: 1, order_by: { time: desc_nulls_last }) {
          id
          time
          type
          value
        }
        notes(order_by: { time: desc }) {
          id
          description
          time
          user {
            id
            email
          }
        }
      }
    }
  }
`;

const QUERY_SPACEMON_DATA = gql`
  query getSpacemonData($siteId: Int!) {
    site_by_pk(id: $siteId) {
      id
      assets {
        id
        description
        measurement_points(
          where: { measurement_point: { device_type: { _eq: "SpaceMon" } } }
        ) {
          id
          measurement_point {
            id
            spacemon_measurement_data(order_by: { time: desc }, limit: 1) {
              id
              co2_concentration
              relative_humidity
              temperature
            }
          }
        }
      }
    }
  }
`;

const QUERY_SITE_SETTINGS = gql`
  query getSiteSettings($siteId: Int!) {
    site_by_pk(id: $siteId) {
      id
      facility_page_settings
    }
  }
`;

const TITLES_WEB = [
  { Header: "Asset", accessor: "asset" },
  { Header: "Description / Summary", accessor: "description" },
  { Header: "Updated", accessor: "updated" },
  { Header: "Status", accessor: "status" },
  { Header: "Notes", accessor: "notes" },
];

const TITLES_MOBILE = [
  { Header: "Asset", accessor: "asset" },
  { Header: "Description", accessor: "description" },
  { Header: "Updated", accessor: "updated" },
  { Header: "Status", accessor: "status" },
  { Header: "Notes", accessor: "notes" },
];

const ISSUE_STATUS = {
  0: "Pending",
  2: "In Progress",
};

const Home = (props) => {
  const siteId = getQueryParameter("siteId");
  const [airData, setAirData] = useState();
  const [service, setService] = useState("issues");
  const [issues, setIssues] = useState();
  const [localIssues, setLocalIssues] = useState([]);
  const [externalIssues, setExternalIssues] = useState([]);

  const { data: issueData, error: issueError } = useQuery(QUERY_OPEN_ISSUES, {
    skip: service === "airQuality",
    // every minute
    pollInterval: 60000,
    variables: {
      siteId: siteId,
    },
    onError: () => console.log(issueError),
  });

  const { data: siteSettings, error: siteSettingsError } = useQuery(
    QUERY_SITE_SETTINGS,
    {
      variables: {
        siteId: siteId,
      },
      onError: () => console.log(siteSettingsError),
    }
  );

  const { data: spaceMonData, error: spaceMonError } = useQuery(
    QUERY_SPACEMON_DATA,
    {
      variables: {
        siteId: siteId,
      },
      onError: () => console.log(spaceMonError),
    }
  );

  useEffect(() => {
    if (!siteSettings) return;
    if (
      siteSettings.site_by_pk.facility_page_settings.includes("air_quality")
    ) {
      setService("airQuality");
    } else {
      return;
    }
  }, [siteSettings]);

  useEffect(() => {
    if (!spaceMonData) return;
    if (!spaceMonData.site_by_pk.assets.length > 0) return;
    let spacemonData = [];
    spaceMonData.site_by_pk.assets.map((asset) => {
      if (asset.measurement_points.length < 1) {
        return null;
      }
      return spacemonData.push({
        data: asset.measurement_points[0]
          ? asset.measurement_points[0].measurement_point
              .spacemon_measurement_data[0]
          : null,
        ...asset,
      });
    });

    setAirData(spacemonData);
  }, [spaceMonData]);

  useEffect(() => {
    if (!issueData) return;
    let local = [];
    let external = [];
    issueData.site_by_pk.local.map((issue) => {
      return local.push({
        asset: issue.asset.description,
        description: issue.description,
        summary: issue.summary,
        updated: handleTime(issue.issue_states[0].time),
        status: ISSUE_STATUS[issue.status],
        notes: issue.notes.length > 0 ? issue.notes.length : "-",
        id: issue.id,
        resolution: issue.steps_to_resolve,
      });
    });
    issueData.site_by_pk.external.map((issue) => {
      return external.push({
        asset: issue.asset.description,
        description: issue.description,
        summary: issue.summary,
        updated: handleTime(issue.issue_states[0].time),
        status: ISSUE_STATUS[issue.status],
        notes: issue.notes.length > 0 ? issue.notes.length : "-",
        id: issue.id,
      });
    });
    setIssues(issueData.site_by_pk);
    setLocalIssues(local);
    setExternalIssues(external);
  }, [issueData]);

  if (!issues && !airData) return <div></div>;

  return (
    <div className="space-y-2 sm:space-y-4 max-w-5xl sm:m-1 h-full overflow-y-auto pb-36 sm:pl-5">
      {service === "airQuality" && (
        <div className="space-y-2 sm:space-y-4">
          <div className={`text-${props.organisation.theme.secondary} text-xl`}>
            Air Quality Sensing
          </div>
          {airData && (
            <div className="space-y-5">
              {airData.map((spc) => {
                return (
                  <AirQualityPanel
                    data={spc.data}
                    name={spc.description}
                    colors={props.organisation.theme}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      {service === "issues" && issues && (
        <>
          <IssueOverviewPanel
            issueCount={issues.local.length}
            title="Hello, you have..."
            type="Local"
            theme={props.organisation.theme}
          />
          <div className=" w-full border rounded-md bg-white sm:p-3 p-2">
            <IssuesTable
              data={localIssues}
              titles={window.innerWidth > 640 ? TITLES_WEB : TITLES_MOBILE}
              {...props}
            />
          </div>
          <IssueOverviewPanel
            title="Currently..."
            issueCount={issues.external.length}
            theme={props.organisation.theme}
            type="External"
          />
          <div className="w-full border rounded-md bg-white sm:p-3 p-2">
            <IssuesTable
              data={externalIssues}
              titles={window.innerWidth > 640 ? TITLES_WEB : TITLES_MOBILE}
              {...props}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
