import React from "react";
import { XCircleIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router";
import { getQueryParameter } from "../../utils/queryParams";
import classNames from "classnames";

const SubmitFeedbackPanel = (props) => {
  const siteId = getQueryParameter("siteId");
  const orgId = getQueryParameter("organisationId");
  const history = useHistory();

  const handleClick = () => {
    history.push(`/requests/?organisationId=${orgId}&siteId=${siteId}`);
    props.close(undefined);
  };
  return (
    <div
      className={classNames(
        `bg-${props.color}`,
        "rounded-md w-full text-white px-4 py-2 font-semibold"
      )}
    >
      <div className="flex flex-row justify-between">
        <div className="text-xl">Request Submitted</div>
        <div
          className="text-white w-7 h-7 cursor-pointer"
          onClick={() => handleClick()}
        >
          <XCircleIcon />
        </div>
      </div>
      <div className="text-sm">
        Thank you for submitting a request, Your request will be reviewed and
        responded to. <br />
        You can review all open requests for below.
      </div>
    </div>
  );
};

export default SubmitFeedbackPanel;
