import React from "react";
import CircleIndicator from "../CircleIndicator";
import ValueIndicator from "../ValueIndicator";
import classnames from "classnames";

const DisplayPanel = (props) => {
    return (
        <div className="w-full bg-white rounded-md mb-5 border p-1">
            <div className="flex flex-row">
                <div className="sm:text-base text-sm text-gray-700 pl-4 sm:pt-4 pt-1">
                    {props.measurementPoint.name}
                </div>
            </div>
            <div className="flex flex-row">
                <div className="flex flex-row justify-between w-full">
                    <div className="self-center pl-4 flex flex-row">
                        <ValueIndicator
                            name={props.measurementPoint.valueName}
                            data={props.measurementPoint.values}
                            color={props.color}
                        />

                        {window.innerWidth > 640 && (
                            <div
                                className={classnames(
                                    `${
                                        props.measurementPoint.size === "small"
                                            ? "invisible"
                                            : "visible"
                                    }`,
                                    "border-r border-gray-300 h-10 sm:h-14 self-center pl-3 sm:pl-5 lg:pl-10 xl:pl-20"
                                )}
                            />
                        )}
                        {(props.type === "WashWatch" ||
                            props.type === "Vac") && (
                            <div className="sm:pl-10 md:pl-5 pl-3  flex">
                                <ValueIndicator
                                    name="Current Mode"
                                    data={props.measurementPoint.mode}
                                    color={props.color}
                                />
                            </div>
                        )}
                        {props.type === "DoorInformer" && (
                            <div className="sm:pl-10 md:pl-5 pl-3 flex">
                                <ValueIndicator
                                    name={"Current Mode"}
                                    data={props.measurementPoint.mode}
                                    color={props.color}
                                />
                            </div>
                        )}
                        {props.type === "SpaceMon" && (
                            <div className="flex sm:pl-10 md:pl-5 pl-3 ">
                                <ValueIndicator
                                    name="Temperature"
                                    data={props.measurementPoint.temp}
                                    color={props.color}
                                />
                                {window.innerWidth > 640 && (
                                    <div
                                        className={classnames(
                                            `${
                                                props.measurementPoint.size ===
                                                "small"
                                                    ? "invisible"
                                                    : "visible"
                                            }`,
                                            "border-r border-gray-300 sm:h-14 h-10 self-center pl-3 sm:pl-5 lg:pl-10 xl:pl-20"
                                        )}
                                    />
                                )}
                                <div className="sm:pl-10 md:pl-5 pl-3 ">
                                    <ValueIndicator
                                        name={"Humidity"}
                                        data={
                                            props.measurementPoint
                                                .relativeHumidity
                                        }
                                        color={props.color}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="pr-2">
                    <CircleIndicator level={props.measurementPoint.issue} />
                </div>
            </div>
        </div>
    );
};

export default DisplayPanel;
