import React from "react";

const CircleIndicator = (props) => {
    return (
        <div className="pb-2.5 flex flex-col">
            <div
                className={
                    props.level === "ISSUE"
                        ? "border-red-500 sm:w-6 sm:h-6 w-5 h-5 rounded-full border-2 border-opacity-30 self-center"
                        : "border-green-400 sm:w-6 sm:h-6 w-5 h-5 rounded-full border-2 border-opacity-30 self-center"
                }
            >
                <div
                    className={
                        props.level === "ISSUE"
                            ? "sm:w-5 sm:h-5 w-4 h-4 rounded-full bg-red-500"
                            : "sm:w-5 sm:h-5 w-4 h-4 rounded-full bg-green-400"
                    }
                ></div>
            </div>
            <div className="text-xs font-semibold text-gray-text"></div>
        </div>
    );
};

export default CircleIndicator;
