import React, { useEffect, useState } from "react";
import RequestPanel from "../../components/RequestPanel/RequestPanel";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { getQueryParameter } from "../../utils/queryParams";
import SubmitFeedbackPanel from "../../components/SubmitFeedbackPanel/SubmitFeedback";
import useWindowDimensions from "../../utils/getWindowDimensions";
import classnames from "classnames";

const QUERY_REQUESTS_BY_SITE = gql`
    query MyQuery($siteId: Int!, $start: timestamptz!) {
        site_by_pk(id: $siteId) {
            id
            open: assets(where: { requests: { open: { _eq: true } } }) {
                requests(
                    order_by: { time: desc }
                    limit: 2
                    where: { time: { _gt: $start }, open: { _eq: true } }
                ) {
                    id
                    description
                    details
                    priority
                    open
                    time
                }
                description
            }
            closed: assets(where: { requests: { open: { _eq: false } } }) {
                requests(
                    order_by: { time_closed: desc }
                    limit: 2
                    where: {
                        time: { _gt: $start }
                        time_closed: { _is_null: false, _gt: $start }
                        open: { _eq: false }
                    }
                ) {
                    id
                    description
                    details
                    open
                    priority
                    time_closed
                }
                description
            }
        }
    }
`;

const Requests = (props) => {
    const [openRequestData, setOpenRequestData] = useState();
    const [closedRequestData, setClosedRequestData] = useState();
    const [weekAgo] = useState(dayjs().subtract(7, "days"));
    const [siteId, setSiteId] = useState(11);
    const id = getQueryParameter("siteId");
    const [submitted, setSubmitted] = useState(getQueryParameter("submitted"));
    const height = useWindowDimensions().height;

    //get issue data for each asset
    const { error: requestDataError, data: requestData } = useQuery(
        QUERY_REQUESTS_BY_SITE,
        {
            fetchPolicy: "network-only",
            variables: {
                start: weekAgo,
                siteId: siteId,
            },
            onError: () => {
                console.log(requestDataError);
            },
        }
    );

    useEffect(() => {
        if (
            requestData === undefined ||
            requestData.site_by_pk === undefined ||
            requestData.site_by_pk.open === undefined ||
            requestData.site_by_pk.closed === undefined
        )
            return;
        const openRequests = [];
        const closedRequests = [];
        requestData.site_by_pk.open.map((request) => {
            if (request.requests !== undefined && request.requests.length > 0) {
                return openRequests.push(request);
            }
            return openRequests;
        });

        requestData.site_by_pk.closed.map((request) => {
            if (request.requests !== undefined && request.requests.length > 0) {
                return closedRequests.push(request);
            }
            return closedRequests;
        });

        setOpenRequestData(openRequests);
        setClosedRequestData(closedRequests);
    }, [requestData]);

    useEffect(() => {
        setSiteId(id);
    }, [id]);

    if (!openRequestData || !closedRequestData) return <div></div>;

    return (
        <div
            className={
                window.innerHeight < 640 && window.innerWidth > 560
                    ? "overflow-y-auto h-full"
                    : "sm:px-5"
            }
        >
            {submitted && (
                <div className="pb-5">
                    <SubmitFeedbackPanel
                        close={setSubmitted}
                        color={props.organisation.theme.primary}
                    />
                </div>
            )}
            <div
                className={classnames(
                    `text-${props.organisation.theme.secondary}`,
                    "text-2xl pb-2.5 "
                )}
            >
                Open Requests
            </div>
            <div
                style={
                    submitted
                        ? { maxHeight: height / 2.9 }
                        : { maxHeight: height / 2.5 }
                }
                className="pt-4 flex flex-col gap-y-5 overflow-y-auto"
            >
                {openRequestData.map((open) => {
                    return <RequestPanel data={open} />;
                })}
            </div>
            <div
                className={classnames(
                    `text-${props.organisation.theme.secondary}`,
                    "text-2xl pt-5 pb-2.5"
                )}
            >
                Closed Requests
            </div>
            {closedRequestData && (
                <div
                    style={
                        submitted
                            ? { maxHeight: height / 2.9 }
                            : { maxHeight: height / 2.5 }
                    }
                    className="pt-4 flex flex-col gap-y-5 overflow-y-auto pb-24"
                >
                    {closedRequestData.map((closed) => {
                        return <RequestPanel data={closed} closed />;
                    })}
                </div>
            )}
        </div>
    );
};

export default Requests;
