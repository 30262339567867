import React from "react";

const DisplayValue = (props) => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row self-center">
                {props.value && (
                    <div
                        className={`text-3xl font-semibold  ${
                            props.value > 0 ? "text-red-500" : "text-green-400"
                        }`}
                    >
                        {props.value}
                    </div>
                )}
            </div>
            <div className={`text-${props.color} text-sm font-medium`}>
                {props.title}
            </div>
        </div>
    );
};

export default DisplayValue;
