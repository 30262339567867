import React from "react";
import RequestForm from "../../components/RequestForm";
import classnames from "classnames";

const ReportIssue = (props) => {
    return (
        <div className="sm:pl-10 pl-4 overflow-y-auto h-full pb-40">
            <div
                className={classnames(
                    `text-${props.organisation.theme.secondary}`,
                    "text-xl"
                )}
            >
                Request
            </div>
            <RequestForm {...props} />
        </div>
    );
};

export default ReportIssue;
