import React, { useEffect, useState } from "react";
import DisplayPanel from "../../components/DisplayPanel";
import { gql, useQuery } from "@apollo/client";
import { getQueryParameter } from "../../utils/queryParams";
import dayjs from "dayjs";

//TODO: update query with hvac/car wash assets/data
const QUERY_MEASUREMENT_POINT_DATA_BY_SITE = gql`
  query assetsBySiteId($siteId: Int!, $dayAgo: timestamptz!) {
    site_by_pk(id: $siteId) {
      id
      temperatureBeacons: assets(
        where: {
          measurement_points: {
            measurement_point: { device_type: { _eq: "TemperatureBeacon" } }
          }
        }
      ) {
        id
        description
        issues(where: { status: { _lte: 2 } }) {
          id
        }
        measurement_points {
          measurement_point {
            id
            beacon_data(order_by: { time: desc }, limit: 1) {
              id
              current_temperature
            }
          }
        }
      }
      doorInformer: assets(
        where: {
          measurement_points: {
            measurement_point: { device_type: { _eq: "DoorInformer" } }
          }
        }
      ) {
        id
        description
        issues(where: { status: { _lte: 2 } }) {
          id
        }
        measurement_points {
          measurement_point {
            id
            doorinformer_statuses(
              where: { mode: { _is_null: false } }
              order_by: { time: desc }
              limit: 1
            ) {
              id
              mode
            }
            doorinformer_measurement_data_aggregate(
              where: { time: { _gte: $dayAgo } }
            ) {
              aggregate {
                sum {
                  count1
                }
              }
            }
          }
        }
      }
      spacemon: assets(
        where: {
          measurement_points: {
            measurement_point: { device_type: { _eq: "SpaceMon" } }
          }
        }
      ) {
        id
        description
        issues(where: { status: { _lte: 2 } }) {
          id
        }
        measurement_points {
          id
          measurement_point {
            id
            name
            spacemon_measurement_data(order_by: { time: desc }, limit: 1) {
              relative_humidity
              co2_concentration
              temperature
              id
            }
          }
        }
      }
      CarWash: assets(
        where: {
          measurement_points: {
            measurement_point: { device_type: { _eq: "WASHWATCH" } }
          }
        }
      ) {
        id
        description
        issues(where: { status: { _lte: 2 } }) {
          id
        }
        measurement_points {
          id
          measurement_point {
            id
            name
            washwatch_sessions_aggregate(
              where: { start_time: { _gte: $dayAgo } }
            ) {
              aggregate {
                count
              }
            }
            washwatch_status(
              limit: 1
              order_by: { last_updated: desc_nulls_last }
            ) {
              id
              last_updated
              wash_active
            }
          }
        }
      }
      Vac: assets(
        where: {
          measurement_points: {
            measurement_point: { device_type: { _eq: "VAC" } }
          }
        }
      ) {
        id
        description
        issues(where: { status: { _lte: 2 } }) {
          id
        }
        measurement_points {
          id
          measurement_point {
            id
            name
            vac_sessions_aggregate(where: { start_time: { _gte: $dayAgo } }) {
              aggregate {
                count
              }
            }
            vacActive: vac_sessions(
              where: { finished: { _eq: false } }
              limit: 1
              order_by: { start_time: desc }
            ) {
              id
            }
          }
        }
      }
    }
  }
`;

const OverView = (props) => {
  const siteId = getQueryParameter("siteId");
  const [dayAgo] = useState(dayjs().startOf("day"));

  const [tempBeaconData, setTempBeaconData] = useState([]);
  const [doorInformerData, setDoorInformerData] = useState([]);
  const [spacemonData, setSpacemonData] = useState([]);
  const [carWashData, setCarWashData] = useState([]);
  const [vacData, setVacData] = useState([]);

  const { data: mpData, error: mpDataError } = useQuery(
    QUERY_MEASUREMENT_POINT_DATA_BY_SITE,
    {
      variables: {
        siteId: siteId,
        dayAgo: dayAgo,
      },
      onError: () => {
        console.log(mpDataError);
      },
    }
  );

  useEffect(() => {
    if (
      !mpData ||
      !mpData.site_by_pk ||
      !mpData.site_by_pk.temperatureBeacons ||
      !mpData.site_by_pk.doorInformer ||
      !mpData.site_by_pk.spacemon
    )
      return;

    let tempBeaconData = [];
    let doorInformerData = [];
    let spacemonData = [];
    let carWashData = [];
    let vacData = [];

    mpData.site_by_pk.Vac.map((asset) => {
      return vacData.push({
        name: asset.description,
        issue: asset.issues.length < 1 ? "NO ISSUE" : "ISSUE",
        size: "large",
        valueName: "Sessions Today",
        values: asset.measurement_points[0]
          ? asset.measurement_points[0].measurement_point.vac_sessions_aggregate
              .aggregate.count
          : 0,
        mode: asset.measurement_points[0]
          ? asset.measurement_points[0].measurement_point.vacActive[0]
            ? "ACTIVE"
            : "IDLE"
          : 0,
      });
    });
    setVacData(vacData);

    mpData.site_by_pk.temperatureBeacons.map((asset) => {
      return tempBeaconData.push({
        name: asset.description,
        values:
          asset.measurement_points[0].measurement_point.beacon_data[0].current_temperature.toFixed(
            1
          ),
        issue: asset.issues.length < 1 ? "NO ISSUE" : "ISSUE",
        size: "small",
        valueName: "Temperature",
      });
    });
    setTempBeaconData(tempBeaconData);

    mpData.site_by_pk.doorInformer.map((asset) => {
      let cycleCount = 0;
      if (
        asset.measurement_points[0].measurement_point &&
        asset.measurement_points[0].measurement_point
          .doorinformer_measurement_data_aggregate.aggregate.sum.count1
      ) {
        cycleCount =
          asset.measurement_points[0].measurement_point
            .doorinformer_measurement_data_aggregate.aggregate.sum.count1;
      }
      return doorInformerData.push({
        name: asset.description,
        values: cycleCount,
        issue: asset.issues.length < 1 ? "NO ISSUE" : "ISSUE",
        size: "large",
        valueName: "Cycles Today",
        mode: setDoorMode(
          asset.measurement_points[0] &&
            asset.measurement_points[0].measurement_point.doorinformer_statuses
              .length > 0
            ? asset.measurement_points[0].measurement_point
                .doorinformer_statuses[0].mode
            : 0
        ),
      });
    });
    setDoorInformerData(doorInformerData);

    mpData.site_by_pk.spacemon.map((asset) => {
      if (
        asset.measurement_points[0].measurement_point.spacemon_measurement_data
          .length > 0
      )
        return spacemonData.push({
          name: asset.description,
          values:
            asset.measurement_points[0].measurement_point
              .spacemon_measurement_data[0].co2_concentration,
          issue: asset.issues.length < 1 ? "NO ISSUE" : "ISSUE",
          size: "large",
          valueName: "CO2",
          relativeHumidity:
            asset.measurement_points[0].measurement_point
              .spacemon_measurement_data[0].relative_humidity,
          temp: asset.measurement_points[0].measurement_point.spacemon_measurement_data[0].temperature.toFixed(
            1
          ),
        });
      return spacemonData;
    });
    setSpacemonData(spacemonData);

    mpData.site_by_pk.CarWash.map((asset) => {
      return carWashData.push({
        name: asset.description,
        values: asset.measurement_points[0]
          ? asset.measurement_points[0].measurement_point
              .washwatch_sessions_aggregate.aggregate.count
          : 0,
        issue: asset.issues.length < 1 ? "NO ISSUE" : "ISSUE",
        mode:
          asset.measurement_points[0] &&
          asset.measurement_points[0].measurement_point.washwatch_status
            .length > 0
            ? asset.measurement_points[0].measurement_point.washwatch_status[0]
                .wash_active
              ? "ACTIVE"
              : "IDLE"
            : "N/A",
        size: "large",
        valueName: "Washes Today",
      });
    });
    setCarWashData(carWashData);
  }, [mpData]);

  const setDoorMode = (mode) => {
    let modes = {
      0: "Unknown",
      1: "AUTO",
      2: "LOCK",
      3: "MANUAL",
      4: "OPEN",
      5: "EXIT",
      6: "Learning",
      7: "P Lock",
      8: "Smoke",
      9: "Delivery",
    };

    return modes[mode];
  };

  return (
    <div className="overflow-y-auto pb-32 sm:h-auto sm:m-2 h-full sm:px-4">
      <div className="grid md:grid-cols-2 xl:grid-cols-3 grid-cols-1 gap-x-5">
        {carWashData.map((mp, index) => {
          return (
            <div key={index}>
              <DisplayPanel
                type="WashWatch"
                measurementPoint={mp}
                color={props.organisation.theme}
              />
            </div>
          );
        })}
        {vacData.length > 0 &&
          vacData.map((mp, index) => {
            return (
              <div key={index}>
                <DisplayPanel
                  type="Vac"
                  measurementPoint={mp}
                  color={props.organisation.theme}
                />
              </div>
            );
          })}
        {doorInformerData.map((mp, index) => {
          return (
            <div key={index}>
              <DisplayPanel
                type="DoorInformer"
                measurementPoint={mp}
                color={props.organisation.theme}
              />
            </div>
          );
        })}
        {spacemonData &&
          spacemonData.map((mp, index) => {
            return (
              <div key={index}>
                <DisplayPanel
                  type="SpaceMon"
                  measurementPoint={mp}
                  color={props.organisation.theme}
                />
              </div>
            );
          })}
      </div>
      <div className="grid 2xl:grid-cols-5 sm:grid-cols-3 grid-cols-1 gap-x-5">
        {tempBeaconData.map((mp, index) => {
          return (
            <div key={index}>
              <DisplayPanel
                type="TemperatureBeacon"
                measurementPoint={mp}
                color={props.organisation.theme}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverView;
