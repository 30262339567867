import cognitoUtils from "../../utils/cognito";
import { clearSession } from "../../utils/userSession";
import { Redirect } from "react-router-dom";
import React from "react";

const Logout = () => {
    cognitoUtils.signOutCognitoSession();
    clearSession();

    return <Redirect to="/" />;
};

export default Logout;
